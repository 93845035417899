<script lang="ts">
  import { configStore, providerStore } from '@src/stores/index'

  import { get } from 'svelte/store'
  import { generate } from 'lean-qr'
  import { analytics } from '@lib/mixpanel/mixpanel'
  import { toSvgSource } from 'lean-qr/extras/svg'
  import { fn, userStore, accessTokenStore } from '@packages/stores'

  import { PassportLocalStorage } from '@packages/utils/enums'

  import { ScanTypes } from '@packages/utils/enums'
  import { PassportId } from '@packages/ui'
  import { getAddress } from '@src/passport/blockchain'
  import socketFn from '@lib/ws/socket-io'

  const { toastAlert } = get(fn)

  let QrCodeSvg: string | null = null
  let message: string = 'Signing your ID...'

  $: if ($configStore.chainId) {
    QrCodeSvg = null
    generateCode()
  }

  const generateCode = async () => {
    try {
      const date = new Date().toISOString()
      const signer = await $providerStore.getSigner()
      analytics.track('cp_passport_id', { date, address: await signer.getAddress() })
      const qrData = JSON.stringify({
        scanType: ScanTypes.PASSPORT_ID,
        date,
        sub: $userStore?.sub,
      })
      await socketFn.connectSocketForSignatures($configStore.credenza.generalWssUrl)
      const code = generate(qrData)
 
      QrCodeSvg = toSvgSource(code, { width: 250, height: 250 })

      try {
        localStorage.setItem(PassportLocalStorage.QR_CODE, qrData)
      } catch (err) {
        toastAlert('QR code caching failed. Please check your browser settings and enable local storage.')
      }
    } catch (err) {
      console.log(err)
      message = 'QR code generation failed'
    }
  }

  const getPass = async () => {
    const { credenza, chainId } = get(configStore)
    const result = await fetch(`${credenza.evmApiUrl}/wallet_pass/${chainId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${get(accessTokenStore)}`,
      },
    })
    if (!result.ok) return toastAlert('Failed to get ApplePass')
    const json = await result.json()
    const link = document.createElement('a');
    link.href = json.download_url;
    link.target = '_blank';
    link.click(); 
  }
</script>

<PassportId
  options={{
    title: 'Show ID',
    description: 'This information is used to verify your personal identity.',
    QrCodeSvg,
    errorMessage: message,
    onClick: generateCode,
    userInfoOptions: {
      storedValueAlias: import.meta.env.PASSPORT_BRAND_STORED_VALUE_ALIAS,
      userData: $userStore,
      userAddress: getAddress(),
    },
    getPass, 
  }}
/>
