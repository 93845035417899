import { writable } from "svelte/store";
import type { TPage } from "../utils/types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const pageOptsStore = writable<any>({});
export const pageStore = writable<TPage | null>(null);

export const forcedPageStore = writable<TPage | null>(null);

export const ppaStore = writable<{ loading: boolean; active: boolean }>({
  loading: false,
  active: false,
});

export const currentPpaActionsStore = writable<{
  primaryAction: {
    text: string;
    contractAddress: string;
    tokenId?: string;
    typeId?: string;
  };
  secondaryAction: { text: string; link: string };
}>();
