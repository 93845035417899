<script lang="ts">
  import { get } from 'svelte/store'
  import { pageOptsStore } from '@packages/stores'
  import { PaymentResult } from '@packages/ui'
  import { configStore } from '@src/stores/config'

  let config = get(configStore)
</script>

<PaymentResult
  options={{
    title: config.content?.paymentResult?.title || 'Success!',
    subtitle: $pageOptsStore?.subtitle || config.content?.paymentResult?.subtitle || 'Your payment was successful!',
    description: config.content?.paymentResult?.description,
  }}
/>
