import { ethers } from '@credenza3/core-web-evm-ext'
import type { TCredenzaContracts } from '@packages/utils/types'
import { derived, writable } from 'svelte/store'

export const providerStore = writable<ethers.BrowserProvider>()
export const contractsStore = writable<TCredenzaContracts>()
export const userAddressStore = derived(providerStore, async ($providerStore) => {
  try {
    if (!$providerStore) return ''

    const signer = await $providerStore.getSigner()
    const address = await signer.getAddress()
    return address.toLowerCase()
  } catch (err) {
    return ''
  }
})
