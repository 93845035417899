
<script lang="ts">
  import Button from "../inputs/button/Button.svelte";

  export let isProgressFinished: boolean
  export let options: {
    text?: string
    selected: {
      src: string
      embedded?: boolean 
    }
    onSetVideoEnded: () => void
  } 
  let { text, onSetVideoEnded } = options

  $: selected = options.selected
</script>

<div class="crtw-w-full">
  {#if text}
    <div class="crtw-text-2xl crtw-font-bold crtw-font-['poppins'] crtw-mb-4">{text}</div>
  {/if}
  {#if selected.embedded}
    <iframe
      id="videoProgressIframe"
      title="progress-video"
      frameborder="0"
      scrolling="no"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
      allowfullscreen
      width="100%"
      style="min-height: 200px"
      src={`${selected.src}?autoplay=1&mute=1`}
    ></iframe>
  {:else if selected.src}
    <video width="100%" controls autoplay muted>
      <source src={selected.src} />
      Your browser does not support the video tag.
    </video>
  {/if}
</div>

{#if isProgressFinished}
  <div class="crtw-pt-4 crtw-w-full">
    <Button onClick={onSetVideoEnded} text="Skip" />
  </div>
{/if}