export enum AUTH_PROVIDERS {
  OAUTH = "OAUTH",
  METAMASK = "METAMASK",
}

export enum OAUTH_LOGIN_TYPE {
  CREDENTIALS = "credentials",
  PASSWORDLESS = "passwordless",
  GOOGLE = "google",
  TICKETMASTER = "ticketmaster",
}

export enum OAUTH_PASSWORDLESS_TYPES {
  EMAIL = "email",
  PHONE = "phone",
}
