<script lang="ts">
  import Button from "../shared/inputs/button/Button.svelte";

  export let options: {
    isCardFocused: boolean
    onPay: () => void
  }
  export let isPaymentDisabled: boolean
  export let isLoading: boolean
  export let totalToPay: string

  let { isCardFocused, onPay } = options
</script>

<div class="crtw-text-base crtw-mb-1.5">Credit card details</div>
<div
  id="cpui-card-element"
  class="crtw-border crtw-border-gray-300 crtw-rounded crtw-px-2.5 crtw-py-4 crtw-mb-2.5 crtw-w-full"
  style={isCardFocused ? 'border-color: #121e2b' : ''}
/>
<!-- Used to display Element errors. -->
<div id="card-errors" role="alert" />
<Button
  text={`Pay $${totalToPay}`}
  onClick={onPay}
  disabled={isPaymentDisabled}
  bind:isLoading
/>