<script lang="ts">
  import Container from "../shared/Container.svelte";
  import ContentHeader from "../shared/ContentHeader.svelte";
  import Button from "../shared/inputs/button/Button.svelte";
  import ButtonLink from "../shared/inputs/button-link/ButtonLink.svelte";
  import Label from "../shared/inputs/input/Label.svelte";
  import CodeInput from "./CodeInput.svelte";

  export let options: {
    isLoading: boolean;
    onBackClick: () => void;
    onSubmit: ({
      confirmPhoneCode,
      confirmEmailCode,
    }: {
      confirmPhoneCode: number;
      confirmEmailCode: number;
    }) => void;
    pending: {
      phone: string;
      email: string;
    };
  };

  let { onBackClick, onSubmit, pending, isLoading } = options;

  let confirmPhoneCode: number;
  let confirmEmailCode: number;
</script>

<ContentHeader text="Before we start please verify your personal data" />

<Container>
  {#if pending?.phone}
    <Label for="code">Enter your code sent to {pending?.phone}</Label>
    <CodeInput bind:value={confirmPhoneCode} placeholder="SMS Code" />
  {/if}
  {#if pending?.email}
    <Label for="code">Enter your code sent to {pending?.email}</Label>
    <CodeInput bind:value={confirmEmailCode} placeholder="Email Code" />
  {/if}
  <Button
    text="Verify"
    onClick={() => onSubmit({ confirmPhoneCode, confirmEmailCode })}
    disabled={isLoading ||
      !!(pending?.phone && String(confirmPhoneCode).length !== 6) ||
      !!(pending?.email && String(confirmEmailCode).length !== 6)}
  />
</Container>

<div class="crtw-mt-4">
  <ButtonLink text="Update your account details" onClick={onBackClick} />
</div>
