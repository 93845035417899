import { ethers } from '@credenza3/core-web-evm-ext'
import { get } from 'svelte/store'

import { providerStore, userAddressStore, contractsStore } from '@src/stores'
import { sendContractTx } from '@src/lib/tx/tx'
import { analytics } from '@lib/mixpanel/mixpanel'
import { PassportEvents, dispatch } from '@lib/events/events'

import type { Passport } from '@src/Passport'
import { sdk } from '@lib/sdk/sdk'
import { Chains } from '@packages/utils/enums'
import { getChainConfig } from '@packages/utils/lib/sdk/chain.config'
import { getNetworkType } from '@packages/utils/chains'
import { configStore } from '@src/stores/config'
import { TEvmContract } from '@packages/utils/types'
import { TSendNftArgs } from '@src/utils/types'

export function getWeb3Provider() {
  return get(providerStore)
}

export async function getAddress() {
  return (await get(userAddressStore)) ?? null
}

export async function switchChain(this: Passport, { chainId }: { chainId: Chains }) {
  if (this.networkType !== getNetworkType(chainId))
    throw new Error(`Passport is initialized in '${this.networkType}' environment.`)

  const chainConfig = getChainConfig(chainId)
  await sdk.evm.switchChain(chainConfig)
  providerStore.set(await sdk.evm.getEthersProvider())
  this.configurePassport({ chainId, config: this.config, clientId: this.config.clientId })
}

export async function getCREDContract(): Promise<TEvmContract> {
  const { address, decimals } = get(contractsStore).erc20Cred
  if (!address || !decimals)
    throw new Error(`${import.meta.env.PASSPORT_BRAND_CRED_ALIAS} is not available for this chainId`)

  const provider = get(providerStore)

  const { erc20MinAbi } = await import('@src/lib/abi')
  const contract = new ethers.Contract(address, erc20MinAbi, provider)

  return { contract, decimals, address }
}

export async function checkMembership(
  membershipOwner: string,
  membershipAddress: string = get(contractsStore).membership.address,
) {
  try {
    if (!membershipAddress) throw new Error('Membership is not available for this chainId')

    const provider = get(providerStore)
    const { membershipMinAbi } = await import('@src/lib/abi')
    const contract = new ethers.Contract(membershipAddress, membershipMinAbi, provider)

    const isMember = await contract.confirmMembership(membershipOwner)
    if (!isMember) return { isMember, meta: {} }

    const meta = await contract.getMembershipMetadata(membershipOwner)
    return { isMember, meta: meta && JSON.parse(meta) }
  } catch (err) {
    return { isMember: false, meta: {} }
  }
}

export async function sendNft({ contract, recipient, tokenId, amount }: TSendNftArgs) {
  const tx = await contract['safeTransferFrom(address,address,uint256,uint256,bytes)'].populateTransaction(
    await getAddress(),
    recipient,
    tokenId,
    amount,
    ethers.ZeroHash,
  )
  const result = await sendContractTx(tx, contract)

  const { clientId, chainId } = get(configStore)
  const transferData = {
    chain: chainId,
    clientId,
    contract: (await contract?.getAddress()) ?? null,
    tokenId,
    from: await getAddress(),
    to: recipient,
    amount,
  }
  dispatch(PassportEvents.SEND_NFT, { ...result, ...transferData })
  analytics.track('cp_transfer_nft', transferData)
  return result
}

export async function sendTokens(address: string, amount: bigint | number, instance?: ethers.Contract) {
  try {
    const decimals = get(contractsStore).erc20Cred.decimals
    const signer = await get(providerStore).getSigner()
    const result = instance
      ? await sendContractTx(await instance.transfer.populateTransaction(address, amount), instance)
      : await signer.sendTransaction({ to: address, value: amount })

    const { chainId, clientId } = get(configStore)
    dispatch(PassportEvents.SEND_TOKENS, result)
    analytics.track('cp_transfer_tokens', {
      chain: chainId,
      clientId,
      contract: (await instance?.getAddress()) ?? null,
      from: await getAddress(),
      to: address,
      amount: ethers.formatUnits(amount, decimals),
    })
    return result
  } catch (err) {
    dispatch(PassportEvents.ERROR, { error: err })
    throw err
  }
}

export const getBalance = async () => {
  try {
    const { contract } = await getCREDContract()
    return await contract.balanceOf(await getAddress())
  } catch (err) {
    dispatch(PassportEvents.ERROR, { error: err })
    throw err
  }
}
