<script lang="ts">
  import Button from "../shared/inputs/button/Button.svelte";

  export let options: {
    formattedBalance: string
    totalPriceToken: number
    balance: bigint
    credAlias: string
    onPay: () => void
    onGetCred: () => void
    totalToPay: string
    isLoading: boolean
    isPaymentDisabled: boolean
  }

  let { totalPriceToken, balance, credAlias, onPay, onGetCred } = options

  $: isLoading = options.isLoading
  $: isPaymentDisabled = options.isPaymentDisabled
  $: formattedBalance = options.formattedBalance
  $: totalToPay = options.totalToPay
</script>

<div class="crtw-w-full crtw-text-center crtw-my-5">
  <div class="crtw-text-3xl crtw-text-black crtw-font-bold">
    {formattedBalance}
  </div>
  <div class="crtw-mb-5 crtw-text-gray-600">
    {#if BigInt(totalPriceToken) > balance}
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      You have not enough funds to perform the payment.
      <span
        class="crtw-text-black crtw-cursor-pointer"
        role="button"
        tabindex="0"
        on:click={onGetCred}
      >
        Click to get some {credAlias}
      </span>
    {:else}
      Your balance after purchase
    {/if}
  </div>

  <Button
    text={`Pay ${totalToPay}`}
    onClick={onPay}
    disabled={isPaymentDisabled}
    {isLoading}
  />
</div>