<script lang="ts">
  import { TAvailableLoginMethod } from "../../../utils/types";
  import ContentHeader from "../shared/ContentHeader.svelte";
  import Loader from "../shared/Loader.svelte";
  import AuthMethods from "./auth-methods/AuthMethods.svelte";
  import CachedQr from "./cachedQr/CachedQr.svelte";

  export let options: {
    isLoading: boolean;
    authTitle: string;
    authDescription: string;
    infoText?: string;
    qrOptions?: {
      qrCodeSvg: string;
      showQr: () => void;
      hideQr: () => void;
    };
    authOptions: {
      loginMethods: TAvailableLoginMethod[];
      handleLogin: (method: TAvailableLoginMethod) => void;
      generateAuthTypeImageHtml: (type: string) => string;
    };
  };

  let { isLoading, authTitle, authDescription, qrOptions, authOptions } =
    options;
</script>

{#if isLoading}
  <Loader />
{:else}
  <div
    class="crtw-flex crtw-flex-col crtw-gap-14 crtw-items-center crtw-w-full"
  >
    <div
      class="crtw-flex crtw-flex-col crtw-gap-3 crtw-items-center crtw-w-full"
    >
      <ContentHeader text={authTitle} description={authDescription} />
      {#if options.infoText}
        <p class="crtw-text-center crtw-text-xs crtw-text-gray-700">
          {options.infoText}
        </p>
      {/if}
    </div>
    <AuthMethods options={{ ...authOptions, isLoading }} />

    {#if qrOptions}
      <CachedQr options={qrOptions} />
    {/if}
  </div>
{/if}
