<script lang="ts">
  import { isLoggedInStore } from "../../stores";
  import { TTheme } from "../../utils/types";
  import MenuItem from "./MenuItem.svelte";
  import ChevronUpIcon from "../images/chevron-up.svg";

  export let options: {
    onClick: () => void;
    title: string;
    subtitle: string;
    label: string;
    passIcon: string;
    chevronStyles: string;
    isMinimized: boolean;
    theme: TTheme;
  };

  let { onClick, title, subtitle, label, passIcon, theme } = options;

  $: chevronStyles = options.chevronStyles;
  $: isMinimized = options.isMinimized;
</script>

<MenuItem
  options={{
    title,
    subtitle,
    onClick,
    isDisabled: false,
    isMinimized,
    theme,
    isMain: true,
  }}
>
  <div
    class={`crtw-flex crtw-justify-center crtw-items-center crtw-rounded-md brand-${label}`}
    slot="icon"
  >
    <img class="crtw-block crtw-max-w-full" src={passIcon} alt="" />
  </div>
  {#if $isLoggedInStore}
    <img src={ChevronUpIcon} alt="" style={chevronStyles} />
  {/if}
</MenuItem>
