import { Chains } from '@packages/utils/enums'

export const erc20CredTokens = {
  [Chains.POLYGON_AMOY]: {
    address: '0x2c88F13a02e2798D214A88A8Be3c7fb82aCeA3a7',
    decimals: 6,
  },
  [Chains.POLYGON]: {
    address: '0x9dc9421D625b734d339357b97d3480eab95B1423',
    decimals: 6,
  },
  [Chains.CHILIZ_SPICY]: {
    address: '0x5619A31C5776c50e4A3f6DD3E07be13f4efa211C',
    decimals: 6,
  },
  [Chains.CHILIZ]: {
    address: '0x5619A31C5776c50e4A3f6DD3E07be13f4efa211C',
    decimals: 6,
  },
} as const

export const membershipContracts = {
  [Chains.POLYGON_AMOY]: {
    address: '0x3d549e5078aa0b168e274493a640a718f5e16647',
  },
  [Chains.POLYGON]: {
    address: '0xae7716da2d2d8269466da09d2121ef976c797da9',
  },
  [Chains.CHILIZ_SPICY]: {
    address: '0x1E483507E4842E3C71EC6BcD85c6d2790B63f890',
  },
  [Chains.CHILIZ]: {
    address: '0x0E53B4BFf707d9BE5582D9D2a4e6c00dc5Fca16F',
  },
} as const
