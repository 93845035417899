<script lang="ts">
  import LogoLinkText from "./LogoLinkText.svelte";
  import CredenzaLogo from "../../../images/credenza.svg";
</script>

<section class="crtw-flex crtw-flex-col crtw-items-center crtw-pt-11">
  <img
    src={CredenzaLogo}
    alt="credenza-logo"
    class="crtw-w-[90px] sm:crtw-w-[100px]"
  />
  <div
    class="crtw-text-center crtw-text-textMain crtw-text-xs crtw-flex crtw-justify-center
    crtw-items-center crtw-pt-[3px] sm:crtw-pt-2"
  >
    <a
      class="crtw-text-hightlightColor crtw-no-underline crtw-mr-1.5 crtw-text-[10px]"
      href="https://www.credenza3.com"
      target="_blank"
      rel="noreferrer">Credenza</a
    >
    <LogoLinkText>·</LogoLinkText>
    <LogoLinkText
      ><a
        class="crtw-text-hightlightColor crtw-no-underline"
        href="https://www.credenza3.com/company/about"
        target="_blank"
        rel="noreferrer">About</a
      ></LogoLinkText
    >

    <LogoLinkText>·</LogoLinkText>
    <LogoLinkText
      ><a
        class="crtw-text-hightlightColor crtw-no-underline"
        href="mailto:info@credenza3.com"
      >
        Contact</a
      ></LogoLinkText
    >
    <LogoLinkText>·</LogoLinkText>
    <LogoLinkText
      ><a
        class="crtw-text-hightlightColor crtw-no-underline"
        href="https://www.credenza3.com/privacy-policy"
        target="_blank"
        rel="noreferrer">Privacy & terms</a
      ></LogoLinkText
    >
  </div>
</section>
