<script lang="ts">
  import Button from "../shared/inputs/button/Button.svelte";
  import ErrorImage from "../../images/error.svg";

  export let options: {
    title: string;
    description: string;
    image?: string;
    shouldRenderBackButton?: boolean;
    onCloseClick: () => void;
    secondaryAction?: { link: string; text: string, openInNewTab?: boolean };
    primaryActionText?: string;
    openPaymentFunction?: (...args: unknown[]) => unknown;
  };

  let {
    title,
    description,
    image,
    shouldRenderBackButton,
    onCloseClick,
    primaryActionText,
    openPaymentFunction,
    secondaryAction,
  } = options;
</script>

<div class="crtw-flex crtw-flex-col crtw-justify-center crtw-items-center">
  <div class="crtw-mb-6">
    {#if image}
      <img class="crtw-w-[90px]" src={image} alt="error" />
    {:else}
      <img src={ErrorImage} alt="error" />
    {/if}
  </div>

  <div class="crtw-text-2xl crtw-uppercase crtw-text-black crtw-font-bold">
    {title}
  </div>

  <div class="crtw-text-base crtw-text-black crtw-text-center crtw-mb-9">
    {description}
  </div>

  {#if shouldRenderBackButton !== false}
    <Button text="Back so site" onClick={onCloseClick} />
  {/if}
</div>

{#if primaryActionText && openPaymentFunction}
  <Button text={primaryActionText} onClick={openPaymentFunction} />
{/if}

{#if secondaryAction?.text && secondaryAction?.link}
  <a
    href={secondaryAction?.link}
    target={secondaryAction.openInNewTab ? "_blank" : "_self"}
    class="crtw-flex crtw-justify-center crtw-items-center crtw-w-full crtw-h-[50px] crtw-cursor-pointer
    crtw-border-2 crtw-border-black crtw-rounded crtw-font-['Poppins'] crtw-text-base crtw-font-medium"
    >{secondaryAction?.text}
  </a>
{/if}
