import { get } from 'svelte/store'
import { accessTokenStore } from '@packages/stores'
import { RecieptTarget } from '@packages/utils/enums'
import { configStore } from '@src/stores/config'

export const requestReceipt = async ({
  hash,
  stripeChargeId,
  title,
  subtitle,
}: {
  hash?: string
  stripeChargeId?: string
  title?: string
  subtitle?: string
}) => {
  const { chainId, credenza, recieptTarget } = get(configStore)

  const buildPaymentObject = hash
    ? { payment: { transaction: { chainId, hash } } }
    : { payment: { stripe: { chargeId: stripeChargeId } } }

  const result = await fetch(`${credenza.apiUrl}/payments/receipt`, {
    method: 'POST',
    body: JSON.stringify({
      ...buildPaymentObject,
      target: recieptTarget || RecieptTarget.EMAIL,
      title,
      subtitle,
      ...(import.meta.env.PASSPORT_BRAND_LABEL ? { header: import.meta.env.PASSPORT_BRAND_LABEL } : {}),
    }),
    headers: {
      Authorization: `Bearer ${get(accessTokenStore)}`,
      'Content-Type': 'application/json',
    },
  })

  return result.ok
}
