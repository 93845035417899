import { get } from 'svelte/store'

import { configStore } from '@src/stores/config'
import { PassportEvents, dispatch } from '@lib/events/events'
import {
  getTokensByUserAddress,
  requestAirDropToken,
  requestLoyaltyPoints as requestLoyalty,
} from '@src/Passport.service'
import { analytics } from '@lib/mixpanel/mixpanel'

import { TAirDrop } from '@src/utils/types'
import { getAddress } from './blockchain'

export function getRoles() {
  return Object.values({
    ADMIN: 'admin',
    USER: 'user',
    CLIENT: 'client',
    SUPER_ADMIN: 'super_admin',
  })
}

export async function getTokens(tokenType: 'token' | 'nft' = 'token') {
  try {
    const currentUserAddress = await getAddress()
    return await getTokensByUserAddress({
      address: currentUserAddress,
      tokenType,
    })
  } catch (err) {
    dispatch(PassportEvents.ERROR, { error: err })
    throw err
  }
}

export async function getNfts() {
  return await getTokens('nft')
}

export async function requestAirDrop({ contractAddress, tokenId, amount, targetAddress }: TAirDrop) {
  try {
    const res = await requestAirDropToken({ contractAddress, tokenId, amount, targetAddress })
    dispatch(PassportEvents.REQUEST_AIRDROP, res)
    dispatch(PassportEvents.RECHECK_BALANCE)

    const { chainId, clientId } = get(configStore)
    analytics.track('cp_airdrop', {
      chain: chainId,
      clientId,
      contract: contractAddress,
      tokenId,
      to: targetAddress,
      amount,
    })
  } catch (err) {
    dispatch(PassportEvents.ERROR, { error: { message: err.statusText, title: 'Air drop failed', code: err.status } })
    throw err
  }
}

export async function requestLoyaltyPoints(eventId: string, contractAddress: string) {
  try {
    const res = await requestLoyalty(eventId, contractAddress)
    dispatch(PassportEvents.REQUEST_LOYALTY_POINTS, res)
    const { chainId, clientId } = get(configStore)
    analytics.track('cp_request_loyalty', {
      chain: chainId,
      clientId,
      contract: contractAddress,
      eventId,
      from: await getAddress(),
    })
  } catch (err) {
    dispatch(PassportEvents.ERROR, {
      error: { message: err.statusText, title: 'Request loyalty points failed', code: err.status },
    })
    throw err
  }
}
