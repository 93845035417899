<script lang="ts">
  import Header from "./shared/Header.svelte";
  import Footer from "./shared/footer/Footer.svelte";

  import Container from "./shared/Container.svelte";

  export let shouldBlurBackdrop: boolean;
  export let isHeaderShown: boolean;

  export let onCloseClick: () => void;
</script>

<div id="cpui" class="crdz-ui">
  <div
    class="crtw-font-['Poppins'] crtw-fixed crtw-z-[1050] crtw-top-0 crtw-left-0 crtw-min-w-screen crtw-w-full
    crtw-min-h-screen crtw-h-full crtw-flex crtw-justify-center crtw-items-center crtw-bg-black/70
    crtw-text-[#121e2b]"
    class:crtw-backdrop-blur-md={shouldBlurBackdrop}
  >
    <div
      class="crtw-bg-white crtw-rounded-lg crtw-relative crtw-w-full crtw-overflow-y-scroll
      crtw-max-w-[380px] crtw-max-h-full sm-tall:crtw-max-h-[650px] sm:crtw-max-w-md"
    >
      <Header {onCloseClick} {isHeaderShown} />
      <Container class="crtw-pt-11 crtw-pb-9 sm:crtw-pt-[50px] crtw-px-[22px]">
        <div
          class="crtw-flex crtw-flex-col crtw-items-center crtw-justify-center crtw-bg-white crtw-rounded
           crtw-min-h-[155px] crtw-gap-6"
        >
          <slot />
        </div>
        <Footer />
      </Container>
    </div>
  </div>
</div>

<style>
  :global(body) {
    --w3m-z-index: 1000001;
  }
</style>
