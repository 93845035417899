<script lang="ts">
  import { isLoggedInStore } from "../../stores";

  export let brandName: string;
</script>

<div
  class={`crtw-w-1.5 crtw-h-1.5 crtw-rounded-[3px] crtw-absolute crtw-left-0 crtw-top-[8px] brand-${brandName}`}
  class:crtw-bg-green-500={$isLoggedInStore}
  class:crtw-bg-red-500={!$isLoggedInStore}
/>

<style>
  .brand-credenza {
    top: 8px;
    left: -1px;
  }
  .brand-nhl {
    top: 2px;
  }
  .brand-mlb.full {
    top: initial;
    bottom: 8px;
    left: 7px;
  }
</style>
