<script lang="ts">
  import { get } from 'svelte/store'
  import { fn } from '@packages/stores'
  import { Sign } from '@packages/ui'
  import { pageOptsStore } from '@packages/stores'
  import { providerStore } from '@src/stores'
  import socketFn from  '@lib/ws/socket-io'

  const { close } = get(fn)

  const handleSignTransaction = async () => {
    const signer = await $providerStore.getSigner()
    const signature = await signer.signMessage($pageOptsStore.message)
    socketFn.signDataAndEmitEvent(signature, $pageOptsStore.socketEventDataHeaders)
    close()
  }
</script>

<Sign
  options={{
    title: 'Sign Transaction Request',
    description: 'Please sign the requested transaction.',
    onCancel: close,
    onConfirm: handleSignTransaction,
  }}
/>
