import { writable } from "svelte/store";
import type {
  TUser,
  TFnWithoutPassportContext,
  TEvmContract,
} from "../utils/types";

import AbstractPassport from "../utils/classes/passport.abstract";

export const accessTokenStore = writable<string | null>();
export const userStore = writable<TUser | null>(null);

export const suiPackageStore = writable<string>();
export const isLoggedInStore = writable<boolean>();
export const isLoggingInProcessStore = writable<boolean>();

export const fn = writable<{
  openUI: AbstractPassport["openUI"];
  close: AbstractPassport["close"];
  toastAlert: AbstractPassport["toastAlert"];
  logout: AbstractPassport["logout"];
  login: TFnWithoutPassportContext<AbstractPassport["login"]>;
  updateProfile: AbstractPassport["updateProfile"];
  confirmAccount: AbstractPassport["confirmAccount"];
  sendTokens: AbstractPassport["sendTokens"];
  getCREDContract: () => Promise<TEvmContract> | string;
  checkMembership: AbstractPassport["checkMembership"];
  requestAirDrop: AbstractPassport["requestAirDrop"];
  requestLoyaltyPoints: AbstractPassport["requestLoyaltyPoints"];
  getBalance: AbstractPassport["getBalance"];
}>();
