import { Chains } from '@packages/utils/enums'
import { TProfileLinks } from '@packages/utils/types'
import { configStore } from '@src/stores/config'
import { get } from 'svelte/store'
import { CHAIN_INFO } from '@packages/utils/chains'

// eslint-disable-next-line max-lines-per-function, complexity
export const getProfileLinks = (address: string): TProfileLinks => {
  const { chainId } = get(configStore)
  switch (chainId) {
    case Chains.POLYGON_AMOY:
      return {
        scanName: 'Polygonscan',
        scanIcon: 'https://amoy.polygonscan.com/assets/poly/images/svg/logos/polygon-chain.svg?v=23.11.1.0',
        scanLink: CHAIN_INFO[Chains.POLYGON_AMOY].scanUrl + '/address/' + address,
        nftsIcon: 'https://opensea.io/static/images/logos/opensea.svg',
        nftsLink: 'https://testnets.opensea.io/' + address,
        passportLink: 'https://passport.testnets.credenza3.com',
      }
    case Chains.POLYGON:
      return {
        scanName: 'Polygonscan',
        scanIcon: CHAIN_INFO[Chains.POLYGON].scanUrl + '/assets/poly/images/svg/logos/polygon-chain.svg?v=23.11.1.0',
        scanLink: CHAIN_INFO[Chains.POLYGON].scanUrl + '/address/' + address,
        nftsIcon: 'https://opensea.io/static/images/logos/opensea.svg',
        nftsLink: 'https://opensea.io/' + address,
        passportLink: 'https://passport.credenza3.com',
      }
    case Chains.CHILIZ_SPICY:
      return {
        scanName: 'Explorer',
        scanIcon: 'https://cloudflare-ipfs.com/ipfs/bafkreic5h74pwuf3ony3c5zmqoenps4pxj7l5q5j3o2mdcflfpb7g5tivy',
        scanLink: CHAIN_INFO[Chains.CHILIZ_SPICY].scanUrl + '/address/' + address,
        passportLink: 'https://passport.testnets.credenza3.com',
      }
    case Chains.CHILIZ:
    default:
      return {
        scanName: 'Explorer',
        scanIcon: 'https://cloudflare-ipfs.com/ipfs/bafkreic5h74pwuf3ony3c5zmqoenps4pxj7l5q5j3o2mdcflfpb7g5tivy',
        scanLink: CHAIN_INFO[Chains.CHILIZ].scanUrl + '/address/' + address,
        passportLink: 'https://passport.credenza3.com',
      }
  }
}
