<script lang="ts">
  import { onDestroy } from 'svelte'
  import { get } from 'svelte/store'
  import { pageOptsStore, fn } from '@packages/stores'
  import { Error } from '@packages/ui'
  import { ERRORS, handleErrorData } from './error.helper'
  import { Pages } from '@packages/utils/enums'

  const handleCloseClick = () => get(fn).close()
  const { openUI } = get(fn)

  let errorData: {
    errorMessage?: string
    image?: string
    description?: string
    title?: string
    shouldRenderBackButton?: boolean
    primaryAction?: { text: string; contractAddress?: string; tokenId?: string; typeId?: string }
    secondaryAction?: { text: string; link: string, openInNewTab?: boolean }
  }
  const unsub = pageOptsStore.subscribe((data) => {
    if (data.errorMessage) errorData = { ...errorData, ...handleErrorData(data.errorMessage) }
    else if (data.title && data.description) errorData = { ...errorData, ...data }
  })

  const openPayment = () => {
    const { primaryAction } = errorData
    let tokens = { tokens: [{ contractAddress: primaryAction?.contractAddress, tokenId: primaryAction?.tokenId }] }
    let memberships = {
      memberships: [{ contractAddress: primaryAction?.contractAddress, typeId: primaryAction?.typeId }],
    }
    openUI(Pages.PAYMENT, {
      title: primaryAction?.text,
      subtitle: '',
      ...(primaryAction?.typeId ? memberships : tokens),
    })
  }

  onDestroy(unsub)
</script>

<Error
  options={{
    title: errorData?.title || ERRORS.titles.DEFAULT_ERROR_TITLE,
    description: errorData?.description || ERRORS.desctiptions.DEFAULT_ERROR_DESC,
    image: errorData?.image,
    shouldRenderBackButton: errorData?.shouldRenderBackButton,
    onCloseClick: handleCloseClick,
    secondaryAction: errorData?.secondaryAction,
    primaryActionText: errorData?.primaryAction?.text,
    openPaymentFunction: openPayment,
  }}
/>
