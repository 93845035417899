<script lang="ts">
  import { onMount } from 'svelte'
  import { get } from 'svelte/store'
  import type { Scanner } from '@credenza3/scanner-lib'

  import { pageStore, fn } from '@packages/stores'
  import { ScanTypes } from '@packages/utils/enums'
  import { once, PassportEvents } from '@lib/events/events'
  import {
    goToPayment,
    goToSendTokens,
    processAirDrop,
    processRequestLoyaltyPoints,
  } from '@pages/scanner/Scanner.service'
  import { Scanner as ScannerComponent } from '@packages/ui'
  import { getUserAddressBySub } from '@src/Passport.service'

  const { close } = get(fn)
  let scanner: Scanner
  let isScannerLoaded = false

  const onCapture = async ({
    message,
    sub,
    scanType,
  }: {
    sub: string
    message: string
    scanType: string
  }) => {
    scanner.close()
    switch (scanType) {
      case ScanTypes.PASSPORT_ID_PASS:
      case ScanTypes.PASSPORT_ID: {
        const parsed = JSON.parse(message)
        const { address } = await getUserAddressBySub(sub)
        goToSendTokens({ userAddress: address, amount: parsed?.amount })
        break
      }
      case ScanTypes.TOKENS_PURCHASE: {
        goToPayment(JSON.parse(message))
        break
      }
      case ScanTypes.AIR_DROP: {
        await processAirDrop(JSON.parse(message))
        break
      }
      case ScanTypes.REQUEST_LOYALTY_POINTS: {
        await processRequestLoyaltyPoints(JSON.parse(message))
        break
      }
      default: {
        close()
        pageStore.set(null)
      }
    }
  }

  onMount(async () => {
    const { Scanner } = await import('@credenza3/scanner-lib')
    scanner = new Scanner({ target: '#cpuiScanner' })
    once(PassportEvents.UI_CLOSED, () => {
      scanner.close()
      pageStore.set(null)
    })
    await scanner.scan({ isCloseButton: false })
    isScannerLoaded = true
    scanner.on(Scanner.events.CAPTURE, onCapture)
    scanner.on(Scanner.events.ERROR, (err) => {
      const message = err?.message || err
      if (message.includes('Permission'))
        alert('In order to scan please allow the current page to access your camera and try again.')
    })
  })
</script>

<ScannerComponent options={{ isScannerLoaded }} />

