<script lang="ts">
  import ContentHeader from "../shared/ContentHeader.svelte";
  import ButtonBack from "../shared/inputs/button-back/ButtonBack.svelte";
  import Button from "../shared/inputs/button/Button.svelte";
  import Input from "../shared/inputs/input/Input.svelte";
  import ScanIcon from "../../images/scanner.svg";

  import { getCryptoCurrencySymbol } from "../../../utils/lib/strings/strings";

  export let recipientAddress: string;
  export let amount: number | undefined;

  export let options: {
    title: string;
    isScannerHidden: boolean;
    balance: string;
    chainId?: string;
    isRecipientAddressValid: boolean;
    onScannerClicked: () => void;
    onSendTokens: () => void;
    onBackClicked: () => void;
    onChangeCurrency: () => void;
    onSetMaxAmount: () => void;
    isLoading: boolean;
    isSubmitDisabled: boolean;
    credAlias: string;
    activeCurrency: string;
    symbol: string;
    nativeSymbol: string;
    nativeBalance: string;
    isNativeCurrecyTransferAllowed?: boolean;
  };

  let {
    onChangeCurrency,
    onSetMaxAmount,
    onBackClicked,
    onSendTokens,
    title,
    chainId,
    onScannerClicked,
    credAlias,
    isNativeCurrecyTransferAllowed = true
  } = options;

  $: balance = options.balance;
  $: isLoading = options.isLoading;
  $: isSubmitDisabled = options.isSubmitDisabled;
  $: isRecipientAddressValid = options.isRecipientAddressValid;
  $: isScannerHidden = options.isScannerHidden;
  $: activeCurrency = options.activeCurrency;
  $: symbol = options.symbol;
  $: nativeSymbol = options.nativeSymbol;
  $: nativeBalance = options.nativeBalance;

  $: activeCurrencySymbol = getCryptoCurrencySymbol(activeCurrency, credAlias);
</script>

<ContentHeader
  text={title}
  description={isScannerHidden
    ? `Send your ${activeCurrencySymbol === symbol ? getCryptoCurrencySymbol(symbol, credAlias) : nativeSymbol}`
    : "Scan Passport Id"}
/>
<div class="crtw-w-full crtw-p-0 crtw-relative">
  <div
    id="cpuiCredScanner"
    class="crtw-absolute crtw-top-0 crtw-bottom-0 crtw-left-0 crtw-right-0 crtw-z-10 crtw-border crtw-border-gray-300 crtw-rounded-lg crtw-backdrop-blur-lg"
    class:crtw-hidden={isScannerHidden}
    class:crtw-z-[-1]={isScannerHidden}
  ></div>
  {#if chainId}
    <div>ChainId: <span class="crtw-font-bold">{chainId}</span></div>
  {/if}
  <div>
    Your balance: 
    <span class="crtw-font-bold">
      {#if isNativeCurrecyTransferAllowed}
        {nativeBalance} {nativeSymbol} / 
      {/if}

      {balance} {getCryptoCurrencySymbol(symbol, credAlias)}
    </span>
  </div>
  <div
    class="crtw-mb-2.5 crtw-flex crtw-items-start crtw-justify-between crtw-w-full"
  >
    <div class="crtw-flex crtw-flex-col crtw-w-full">
      <Input
        name="recipientAddress"
        type="text"
        placeholder="Address 0x..."
        bind:value={recipientAddress}
      />
      {#if recipientAddress && !isRecipientAddressValid}
        <label
          class="crtw-text-[10px] crtw-text-warningTextColor cpui-send-tokens-validation-message"
          for="recipientAddress">Provided address is not valid.</label
        >
      {/if}
    </div>

    <div class="crtw-ml-2.5 crtw-cursor-pointer">
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
      <img
        src={ScanIcon}
        on:click={onScannerClicked}
        width="53px"
        alt=""
        class="crtw-h-[53px]"
      />
    </div>
  </div>

  <div
    class="crtw-mb-2.5 crtw-flex crtw-items-start crtw-justify-between crtw-w-full"
  >
    <Input
      type="number"
      min="0"
      step="0.001"
      placeholder={`${activeCurrencySymbol} amount`}
      bind:value={amount}
    />
    <div class="crtw-ml-2.5 crtw-w-16 crtw-min-w-16">
      <Button onClick={onSetMaxAmount} text={`MAX`} disabled={+balance <= 0} />
    </div>

    {#if isNativeCurrecyTransferAllowed}
      <div class="crtw-ml-2.5 crtw-w-16 crtw-min-w-16">
        <Button
          onClick={onChangeCurrency}
          text={activeCurrencySymbol}
          disabled={!activeCurrency}
        />
      </div>
    {/if}
  </div>
  <Button
    onClick={onSendTokens}
    text={`Send ${amount || ""} ${activeCurrencySymbol}`}
    {isLoading}
    disabled={isSubmitDisabled}
  />
</div>
<div class="crtw-mt-0.5 crtw-mb-3 crtw-w-full">
  <ButtonBack onClick={onBackClicked} />
</div>
