import { io, Socket } from "socket.io-client";
import { accessTokenStore, fn } from "../../../stores";
import { get } from "svelte/store";
import { TSocketEventData } from "../../types";
import { Pages } from "../../enums";

let client: Socket;

const connectSocketForSignatures = async (wssUrl: string) => {
  if (client) return;

  client = io(wssUrl, {
    auth: {
      bearer_token: get(accessTokenStore),
    },
  });

  await new Promise((resolve) => {
    client.on("connect", () => {
      resolve(true);
    });
  });

  client.on("passport_id/sign", onSignRequest);
};

const signDataAndEmitEvent = async (
  signature: string,
  headers: TSocketEventData["headers"]
) => {
  try {
    if (!client) throw "Socket client not connected";
    client.emit(headers.emit_event, {
      headers: headers.emit_event_headers,
      payload: { signature },
    });
  } catch (err) {
    console.error(err);
  }
};

const onSignRequest = async (data: TSocketEventData) => {
  const { openUI } = get(fn);
  openUI(Pages.SIGN, {
    message: data.payload.message,
    socketEventDataHeaders: data.headers,
  });
};

export default {
  connectSocketForSignatures,
  signDataAndEmitEvent,
};
