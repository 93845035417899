export const config = {
  testnet: {
    credenza: {
      apiUrl: "https://api.testnets.credenza.online",
      generalWssUrl: "wss://general-staging-staging.up.railway.app",
      gitbookUrl: `https://developer.credenza3.com/credenza-sdk-v3.0/`,
      evmApiUrl: "https://evm.staging.credenza3.com",
      suiApiUrl: "https://sui.staging.credenza3.com",
    },
    stripe: {
      pk: "pk_test_51LJ0nZHfOaPNdSbZUzB3H4D44JoFD0AIfYv4QLSSSxrBs8ch4A5UlpJOXF4OG0fkgppBD2jOrDTCNaGzevnxdH2200rI1wY1cC",
    },
  },
  mainnet: {
    credenza: {
      apiUrl: "https://api.credenza.online",
      gitbookUrl: `https://developer.credenza3.com/credenza-sdk-v3.0/`,
      evmApiUrl: "https://evm.credenza3.com",
      suiApiUrl: "https://sui.credenza3.com",
      generalWssUrl: "wss://general-prod-prod.up.railway.app",
    },
    stripe: {
      pk: "pk_live_51LJ0nZHfOaPNdSbZpj7gaAUfmbfWylFaORiGxRxyVn8efm1hMTP6Cz2ihL1AhEcUu9AyqTKsUblMQL5m0YU6HTg3001izgq3Fj",
    },
  },
};
