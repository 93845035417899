<script lang="ts">
  import Loader from "../../Loader.svelte";

  export let onClick: (...args: unknown[]) => unknown;
  export let text: string;
  export let disabled: boolean = false;
  export let isLoading: boolean = false;
</script>

<button
  class:crtw-bg-gray-300={disabled || isLoading}
  class:crtw-pointer-events-none={disabled || isLoading}
  class="crtw-flex crtw-justify-center crtw-items-center crtw-w-full crtw-bg-black crtw-text-white crtw-h-[50px] crtw-cursor-pointer
  crtw-border-0 crtw-rounded crtw-font-['Poppins'] crtw-text-base crtw-font-medium"
  on:click|preventDefault={onClick}
>
  {#if isLoading}
    <Loader />
  {:else}
    {text}
  {/if}
</button>
