<script lang="ts">
  import ContentHeader from "../shared/ContentHeader.svelte";
  import AppleWalletImg from "../../images/apple-wallet.svg";
  import ReloadIcon from "../../images/reload.svg";
  import UserInfo from "../shared/UserInfo.svelte";
  import { TUser } from "../../../utils/types";

  export let options: {
    title: string;
    description: string;
    QrCodeSvg: string | null;
    errorMessage: string;
    onClick: () => void;
    userInfoOptions: {
      storedValueAlias: string;
      balance?: string;
      userData: TUser | null;
      userAddress: Promise<string>;
    };
    getPass?: () => Promise<void>;
  };

  let {
    title,
    description,
    errorMessage,
    onClick,
    userInfoOptions,
    getPass,
  } = options;

  $: balance = userInfoOptions.balance;
  $: QrCodeSvg = options.QrCodeSvg;
</script>

<ContentHeader text={title} {description} />
<UserInfo options={{ ...userInfoOptions, balance }} />
<div
  class="crtw-flex crtw-flex-col crtw-justify-center crtw-items-center crtw-w-full"
>
  {#if QrCodeSvg}
    <div>{@html QrCodeSvg}</div>
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div
      class="crtw-flex crtw-items-center crtw-cursor-pointer crtw-text-xs crtw-mt-[-10px] crtw-gap-2.5 crtw-font-medium"
      on:click={onClick}
      role="button"
      tabindex="0"
    >
      <img src={ReloadIcon} alt="reload-icon" class="crtw-w-4" />
      Refresh code
    </div>

    {#if getPass}
      <a href="/" on:click|preventDefault={getPass} style="margin-top: 10px">
        <img src={AppleWalletImg} alt="Add to Apple Wallet" />
      </a>
    {/if}
    
  {:else}
    <div class="crtw-py-2.5 crtw-text-sm">{errorMessage}</div>
  {/if}
</div>
