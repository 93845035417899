import { get } from 'svelte/store'

import { analytics } from '@lib/mixpanel/mixpanel'
import { PassportEvents, dispatch } from '@lib/events/events'
import { userStore, isLoggedInStore, accessTokenStore } from '@packages/stores'
import { PassportLocalStorage } from '@packages/utils/enums'

import { sdk } from '@lib/sdk/sdk'
import { AUTH_PROVIDERS, OAUTH_LOGIN_TYPE, OAUTH_PASSWORDLESS_TYPES } from '@packages/utils/enums'
import { Passport } from '@src/Passport'
import { getAddress } from './blockchain'
import { configStore } from '@src/stores/config'

export async function login(
  this: Passport,
  provider: AUTH_PROVIDERS,
  {
    forceEmail,
    forcePhone,
    oauthType,
    passwordlessType,
  }: {
    forceEmail?: string
    forcePhone?: string
    oauthType?: OAUTH_LOGIN_TYPE
    passwordlessType?: OAUTH_PASSWORDLESS_TYPES
  } = {},
) {
  void analytics.track('cp_login_initiated', {
    chain: this.config.chainId,
    clientId: this.config.clientId,
    provider: provider,
    location: window.location.origin + window.location.pathname,
  })

  try {
    if (provider === AUTH_PROVIDERS.METAMASK) {
      await sdk.evm.metamask.login()
    } else if (provider === AUTH_PROVIDERS.OAUTH) {
      await sdk.oauth.login({
        scope: 'profile profile.write email phone blockchain.evm.write blockchain.evm',
        redirectUrl: window.location.href,
        ...(oauthType && { type: oauthType }),
        ...(passwordlessType && { passwordlessType }),
        ...(forceEmail && { forceEmail }),
        ...(forcePhone && { forcePhone }),
      })
    } else {
      throw new Error('Unknown provider')
    }
    this.init()
  } catch (err) {
    dispatch(PassportEvents.ERROR, { provider, error: err })
    throw err
  }
}

export async function logout({ shouldRevokeSession = false } = {}) {
  try {
    const config = get(configStore)
    const user = get(userStore)
    analytics.track('cp_logout', {
      chain: config.chainId,
      clientId: config.clientId,
      address: await getAddress(),
      location: window.location.href,
      $email: user?.email,
      $phone: user?.phone,
    })
  } catch {
    /**/
  }

  localStorage.removeItem(PassportLocalStorage.QR_CODE)
  isLoggedInStore.set(false)
  userStore.set(null)
  accessTokenStore.set(null)
  if (shouldRevokeSession) {
    await sdk.oauth.revokeSession()
  }
  sdk.logout()
  dispatch(PassportEvents.LOGOUT)
}
