<script lang="ts">
  import { get } from 'svelte/store'
  import { fn, userStore, pageStore, forcedPageStore } from '@packages/stores'
  import { Pages } from '@packages/utils/enums'
  import { ConfirmAccount } from '@packages/ui'

  const { confirmAccount, close, toastAlert } = get(fn)

  let isLoading = false

  const confirm = async ({
    confirmEmailCode,
    confirmPhoneCode,
  }: {
    confirmEmailCode: number
    confirmPhoneCode: number
  }) => {
    isLoading = true
    try {
      const res = await confirmAccount({
        ...($userStore?.pending?.email ? { emailCode: String(confirmEmailCode) } : {}),
        ...($userStore?.pending?.phone ? { phoneCode: String(confirmPhoneCode) } : {}),
      })

      if (($userStore?.pending?.phone && !res.phone) || ($userStore?.pending?.email && !res.email))
        throw new Error('The code is invalid.')

      const page = get(pageStore)
      if (!page || page === Pages.CONFIRM_ACCOUNT || page === Pages.LOGOUT) close()
      toastAlert('Account has been confirmed successfully.', 'success')
    } catch (err) {
      toastAlert(err?.message || err, 'failure')
    }
    isLoading = false
  }
</script>

<ConfirmAccount
  options={{
    isLoading,
    onBackClick: () => forcedPageStore.set(Pages.UPDATE_PROFILE),
    onSubmit: confirm,
    pending: $userStore?.pending,
  }}
/>
