<script lang="ts">
  import Container from "../shared/Container.svelte";
  import Label from "../shared/inputs/input/Label.svelte";
  import Input from "../shared/inputs/input/Input.svelte";
  import ButtonBack from "../shared/inputs/button-back/ButtonBack.svelte";
  import Button from "../shared/inputs/button/Button.svelte";
  import PhoneInput from "../shared/inputs/phone/PhoneInput.svelte";

  export let name: string;
  export let email: string;
  export let phone: string;
  export let isSubmitDisabled: boolean;
  export let error: string | null;
  export let isEmailInputValid: boolean;
  export let options: {
    isExtendedRegistration: boolean | undefined;
    onProfileUpdate: () => void;
    onBackClick: () => void;
    shouldShowBackButton: boolean;
    isEmailChangeDisabled: boolean;
    isPhoneChangeDisabled: boolean;
  };

  let {
    isExtendedRegistration,
    onProfileUpdate,
    onBackClick,
    shouldShowBackButton,
    isEmailChangeDisabled,
    isPhoneChangeDisabled,
  } = options;
</script>

<Container class="crtw-flex crtw-justify-center crtw-flex-col crtw-gap-2.5">
  <div class="crtw-flex crtw-flex-col">
    <Label for="NameInput"
      >First and last name
      {#if isExtendedRegistration}
        <span class="crtw-text-red-500">*</span>
      {/if}
    </Label>
    <Input
      id="NameInput"
      type="text"
      placeholder="First and last name"
      bind:value={name}
      name="name"
      required={isExtendedRegistration}
    />
  </div>

  <div class="crtw-flex crtw-flex-col">
    <Label for="EmailInput"
      >Email address
      {#if isExtendedRegistration}
        <span class="crtw-text-red-500">*</span>
      {/if}
    </Label>
    <Input
      id="EmailInput"
      type="email"
      bind:isValid={isEmailInputValid}
      placeholder="Email address"
      bind:value={email}
      name="email"
      disabled={isEmailChangeDisabled}
    />
  </div>

  <div class="crtw-flex crtw-flex-col">
    <Label for="PhoneInput">Phone number</Label>
    <PhoneInput bind:phone disabled={isPhoneChangeDisabled} />
  </div>

  <Button
    text="Update profile"
    onClick={onProfileUpdate}
    disabled={isSubmitDisabled}
  />

  {#if error}
    <div class="crtw-text-xs crtw-text-[#990008]">
      - {error}
    </div>
  {/if}

  {#if shouldShowBackButton}
    <div class="crtw-text-left crtw-mt-2.5 crtw-w-full">
      <ButtonBack onClick={onBackClick} />
    </div>
  {/if}
</Container>
