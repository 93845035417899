<script lang="ts">
  import CloseIcon from "../../images/close-button.svg";

  export let onCloseClick: () => void;
  export let isHeaderShown: boolean;

  $: isHeaderShown = isHeaderShown;
</script>

{#if isHeaderShown}
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div
    class="crtw-float-right crtw-cursor-pointer crtw-h-8 crtw-w-8 crtw-absolute crtw-top-0 crtw-right-0 crtw-z-1000001 crtw-m-2 crtw-bg-white crtw-rounded-full sm:crtw-m-4"
    on:click={onCloseClick}
    role="button"
    tabindex={0}
  >
    <img
      src={CloseIcon}
      alt="close-icon"
      class="crtw-fill-red-500 crtw-w-8 crtw-h-8"
    />
  </div>
{/if}
