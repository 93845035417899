<script lang="ts">
  import { onDestroy } from 'svelte'
  import { get } from 'svelte/store'
  import { validate } from '@lib/validation/validation'
  import { fn, userStore, pageStore, forcedPageStore } from '@packages/stores'
  import { Pages, OAUTH_LOGIN_TYPE } from '@packages/utils/enums'
  import { UpdateProfile } from '@packages/ui'
  import { TUser } from '@packages/utils/types'
  import { configStore } from '@src/stores/config'

  const { updateProfile, close, toastAlert } = get(fn)

  let email: string
  let name: string
  let phone: string

  let currentUser: TUser

  let isEmailChangeDisabled: boolean
  let isPhoneChangeDisabled: boolean
  let isSubmitDisabled: boolean

  const unsubscribeUserStore = userStore.subscribe((user) => {
    if (!user) {
      forcedPageStore.set(null)
      close()
      return
    }

    name = user.name || ''
    email = user.email || ''
    phone = user.phone || ''
    currentUser = user

    const isSocialLoginType =
      user.OAUTH_LOGIN_TYPE === OAUTH_LOGIN_TYPE.GOOGLE || user.OAUTH_LOGIN_TYPE === OAUTH_LOGIN_TYPE.TICKETMASTER
    isEmailChangeDisabled = !!user.pending?.email || isSocialLoginType
    isPhoneChangeDisabled = !!user.pending?.phone || isSocialLoginType
  })

  let isLoading = false
  let isEmailValid: boolean = false
  let isEmailInputValid: boolean = true
  let isNameValid: boolean = true
  let error: string = ''

  const executeProfileUpdate = async () => {
    error = ''
    isLoading = true
    try {
      const newData = {
        ...(currentUser.email !== email ? { email } : {}),
        ...(currentUser.name !== name ? { name } : {}),
        ...(currentUser.phone !== phone ? { phone } : {}),
      }
      const updatedUser = await updateProfile(newData)
      forcedPageStore.set(null)

      toastAlert(
        'Account has been updated successfully. Email or phone should be confirmed by entering one-time code.',
        'success',
      )
      const page = get(pageStore)
      if (!updatedUser?.pending?.phone && !updatedUser?.pending?.email && (!page || page === Pages.UPDATE_PROFILE))
        close()
    } catch (err) {
      const errorText = err?.message || 'Error while updating your account details.'
      toastAlert(errorText, 'failure')
      error = errorText
    }
    isLoading = false
  }

  $: {
    isEmailValid = validate(email)
    isEmailInputValid = !email || isEmailValid

    $configStore.auth?.extendedRegistration && (isNameValid = !!name)

    isSubmitDisabled = isLoading || !isEmailInputValid || !isNameValid
  }

  onDestroy(unsubscribeUserStore)
</script>

<UpdateProfile
  bind:error
  bind:name
  bind:email
  bind:phone
  bind:isSubmitDisabled
  bind:isEmailInputValid
  options={{
    isExtendedRegistration: $configStore.auth?.extendedRegistration,
    onProfileUpdate: executeProfileUpdate,
    onBackClick: () => forcedPageStore.set(null),
    shouldShowBackButton: $forcedPageStore === Pages.UPDATE_PROFILE,
    isEmailChangeDisabled,
    isPhoneChangeDisabled,
  }}
/>
