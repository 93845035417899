import { AUTH_PROVIDERS, OAUTH_LOGIN_TYPE, OAUTH_PASSWORDLESS_TYPES } from '@packages/utils/enums'
import { configStore } from '@src/stores/config'
import { get } from 'svelte/store'
import MetamaskIcon from '@images/metamask.svg'
import GoogleIcon from '@images/google.svg'
import PhoneIcon from '@images/phone.svg'
import TicketmasterIcon from '@images/ticketmaster.svg'

export const getAuthMethodsConfigs = () => {
  const config = get(configStore)
  return {
    credentials: {
      oauthType: OAUTH_LOGIN_TYPE.CREDENTIALS,
      text: 'email and password',
      condition: config.auth?.credentials !== false,
    },
    email: {
      oauthType: OAUTH_LOGIN_TYPE.PASSWORDLESS,
      passwordlessType: OAUTH_PASSWORDLESS_TYPES.EMAIL,
      text: 'email only',
      condition: config.auth?.email !== false,
    },
    google: {
      oauthType: OAUTH_LOGIN_TYPE.GOOGLE,
      condition: config.auth?.google !== false,
    },
    ticketmaster: {
      oauthType: OAUTH_LOGIN_TYPE.TICKETMASTER,
      condition: config.auth?.ticketmaster !== false,
    },
    metamask: {
      provider: AUTH_PROVIDERS.METAMASK,
      text: 'metamask',
      condition: config.auth?.metamask !== false && !!window.ethereum,
    },
    phone: {
      oauthType: OAUTH_LOGIN_TYPE.PASSWORDLESS,
      passwordlessType: OAUTH_PASSWORDLESS_TYPES.PHONE,
      text: 'phone only',
      condition: config.auth?.phone !== false,
    },
  }
}

export const generateAuthTypeImageHtml = (type: string) => {
  const containerClasses =
    'crtw-w-[60px] crtw-h-[29px] sm:crtw-w-[66px] sm:crtw-h-[32px] crtw-rounded-[18px] crtw-flex crtw-items-center crtw-justify-center'

  const typeConfig = {
    [OAUTH_LOGIN_TYPE.GOOGLE]: { bgColor: 'crtw-bg-[#C1C1C1]', icon: GoogleIcon },
    [OAUTH_LOGIN_TYPE.TICKETMASTER]: { bgColor: 'crtw-bg-[#016AD9]', icon: TicketmasterIcon },
    [AUTH_PROVIDERS.METAMASK.toLowerCase()]: { bgColor: 'crtw-bg-[#FE8100]', icon: MetamaskIcon },
    [OAUTH_PASSWORDLESS_TYPES.PHONE]: { bgColor: 'crtw-bg-[#000]', icon: PhoneIcon },
    [OAUTH_LOGIN_TYPE.CREDENTIALS]: { bgColor: undefined, icon: undefined },
    [OAUTH_PASSWORDLESS_TYPES.EMAIL]: { bgColor: undefined, icon: undefined },
  }

  const typeConfigEntry = typeConfig[type.toLowerCase()]
  if (!typeConfigEntry) return ''

  const { bgColor, icon } = typeConfigEntry
  const iconImg = icon ? `<img src=${icon} alt="${type}" class="crtw-w-[29px]" />` : ''

  return `<div class="${containerClasses + ' ' + bgColor}">${iconImg}</div>`
}
