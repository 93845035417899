/* eslint-disable complexity */
import { get } from 'svelte/store'
import { userStore, pageOptsStore } from '@packages/stores'
import { requestWs } from '@lib/ws/async-client'
import { requestReceipt } from '../Payment.service'
import { getAddress } from '@src/passport/blockchain'
import { configStore } from '@src/stores/config'

type TPaymentReturn = {
  charge?: {
    id: string
  }
  tokens: {
    tx: {
      hash: string
    }
    contractAddress: string
  }[]
  memberships: {
    tx: {
      hash: string
    }
    contractAddress: string
  }[]
}
export const processPayment = async (stripeTokenId: string) => {
  const config = get(configStore)
  const paymentOpts = get(pageOptsStore)
  const user = get(userStore)
  const userAddress = await getAddress()

  const email = user?.email || user?.pending?.email
  const phone = user?.phone || user?.pending?.phone
  const description =
    'Passport Payment: ' +
    `(${JSON.stringify({
      ...paymentOpts.tokens,
      ...paymentOpts.memberships,
      userAddress,
      chainId: config.chainId,
      email,
      phone,
    })})`

  const paymentData = {
    cardToken: config.stripe.pk.includes('pk_live') ? stripeTokenId : 'tok_visa',
    description,
    address: userAddress,
    chainId: config.chainId,
    tokens: paymentOpts.tokens || [],
    memberships: paymentOpts.memberships || [],
  }
  const res = await requestWs<TPaymentReturn>(config.networkType, 'payments/buyAssets', paymentData)

  if (res.charge?.id)
    await requestReceipt({ stripeChargeId: res.charge.id, title: paymentOpts.title, subtitle: paymentOpts.subtitle })
  return res
}
