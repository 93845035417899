import { derived, writable } from 'svelte/store'
import { TChainId, TPassportConfig } from '@packages/utils/types'
import { forcedPageStore, isLoggedInStore, userStore } from '@packages/stores'
import { Pages } from '@packages/utils/enums'

export const configStore = writable<TPassportConfig<{ chainId: TChainId }>>()
export const forcedPage = derived(
  [forcedPageStore, configStore, userStore, isLoggedInStore],
  ([$forcedPageStore, $configStore, $userStore, $isLoggedInStore]) => {
    // any forced page
    if ($forcedPageStore) {
      return $forcedPageStore
      // not logged in
    } else if (!$isLoggedInStore) {
      return Pages.LOGIN
      // extended reg
    } else if (
      $configStore.auth?.extendedRegistration &&
      (!$userStore?.name ||
        (!$userStore?.email && !$userStore?.pending?.email && !$userStore?.phone && !$userStore?.pending?.phone))
    ) {
      return Pages.UPDATE_PROFILE
      // unconfirmed account
    } else if ($userStore?.pending?.email || $userStore?.pending?.phone) {
      return Pages.CONFIRM_ACCOUNT
    }

    return null
  },
)

export const cloakStore = derived(
  [configStore, isLoggedInStore],
  ([$configStore, $isLoggedInStore]) => !$isLoggedInStore && !!$configStore.content?.cloak,
)
