import mixpanel, { OverridedMixpanel, Mixpanel } from 'mixpanel-browser'
import { NetworkTypes } from '@packages/utils/enums'

export let analytics: Mixpanel | OverridedMixpanel = mixpanel

export const initAnalytics = (networkType: NetworkTypes.MAINNET | NetworkTypes.TESTNET) => {
  const token =
    networkType === NetworkTypes.MAINNET ? '99f02568157d7aa8d54f0843d18e7d91' : '874af62961206fdd0dab0d95ae71dcf7'

  mixpanel.init(
    token,
    {
      debug: false,
      loaded: (mp) => (analytics = mp),
    },
    networkType,
  )
}
