import { fn, pageOptsStore, pageStore } from '@packages/stores'
import { Pages } from '@packages/utils/enums'

import { TTransactionItem, TSendToPassportId } from '@packages/utils/types'
import { get } from 'svelte/store'
import { DEFAULT_LOADER_TITLE } from '@packages/utils/constants'
import { waitVideoEnded } from '@components/progress/ProgressVideo.service'
import { getAddress } from '@src/passport/blockchain'
import { TAirDrop } from '@src/utils/types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const checkTransactionQuery = (params: any): boolean => {
  const hasBasicKeys = ['contractAddress', 'amount', 'title'].every((key) =>
    Object.prototype.hasOwnProperty.call(params, key),
  )

  return hasBasicKeys && (params.tokenId || params.typeId)
}

export const goToPayment = ({
  title,
  typeId,
  amount,
  tokenId,
  contractAddress: address,
  progressVideos,
}: TTransactionItem) => {
  pageOptsStore.set({
    subtitle: title,
    ...(typeId ? { memberships: [{ contractAddress: address, typeId }] } : {}),
    ...(tokenId ? { tokens: [{ contractAddress: address, tokenId, amount }] } : {}),
    loaderText: DEFAULT_LOADER_TITLE,
    progressVideos,
  })
  pageStore.set(Pages.PAYMENT)
}

export const goToSendTokens = ({ userAddress, amount }: TSendToPassportId) => {
  pageOptsStore.set({ to: userAddress, amount })
  pageStore.set(Pages.SEND_TOKENS)
}

export const processAirDrop = async (data: TAirDrop) => {
  const { requestAirDrop } = get(fn)
  try {
    pageOptsStore.set({ loaderText: 'Issuing AirDropped Token...', progressVideos: data.progressVideos })
    pageStore.set(Pages.LOADER)
    const targetAddress = await getAddress()
    await requestAirDrop({ ...data, targetAddress })
    await waitVideoEnded()

    pageOptsStore.set({ subtitle: 'The air drop was successful!', receipt: false })
    pageStore.set(Pages.PAYMENT_RESULT)
  } catch (err) {
    pageOptsStore.set({ errorMessage: err.statusText })
    pageStore.set(Pages.ERROR)
  }
}

export const processRequestLoyaltyPoints = async ({
  eventId,
  contractAddress,
}: {
  eventId: string
  contractAddress: string
}) => {
  const { requestLoyaltyPoints } = get(fn)
  try {
    pageOptsStore.set({ loaderText: 'Issuing Loyalty points..', progressVideos: [] })
    pageStore.set(Pages.LOADER)
    await requestLoyaltyPoints(eventId, contractAddress)

    pageOptsStore.set({ subtitle: 'The request for loyalty points was successful!', description: '', receipt: false })
    pageStore.set(Pages.PAYMENT_RESULT)
  } catch (err) {
    pageOptsStore.set({ description: err.message, title: 'The request failed' })
    pageStore.set(Pages.ERROR)
  }
}
