import { Chains, NetworkTypes } from "./enums";

export const CHAIN_INFO = {
  [Chains.POLYGON]: {
    name: "Polygon",
    rpcUrl: "https://polygon-bor.publicnode.com",
    wsRpcUrl: "wss://polygon-bor.publicnode.com",
    scanUrl: "https://polygonscan.com",
  },
  [Chains.POLYGON_AMOY]: {
    name: "Amoy",
    rpcUrl: "https://rpc.ankr.com/polygon_amoy/",
    wsRpcUrl: "wss://rpc.ankr.com/polygon_amoy/",
    scanUrl: "https://www.oklink.com/amoy",
  },
  [Chains.CHILIZ]: {
    name: "Chiliz",
    rpcUrl: "https://chiliz.publicnode.com/",
    wsRpcUrl: "wss://chiliz.publicnode.com",
    scanUrl: "https://scan.chiliz.com/",
  },
  [Chains.CHILIZ_SPICY]: {
    name: "Spicy",
    rpcUrl: "https://spicy-chain.rpc.thirdweb.com",
    wsRpcUrl: "wss://chiliz-spicy.publicnode.com",
    scanUrl: "https://spicy-explorer.chiliz.com/",
  },
} as const;

export const getNetworkType = (chainId: string) => {
  switch (chainId) {
    case Chains.POLYGON:
    case Chains.CHILIZ:
      return NetworkTypes.MAINNET;
    default:
      return NetworkTypes.TESTNET;
  }
};
