<script lang="ts">
  import { get } from 'svelte/store'

  import {
    pageStore,
    fn,
    pageOptsStore,
  } from '@packages/stores'
  import { Pages } from '@packages/utils/enums'
  import { Wallet } from '@packages/ui'
  import { configStore, providerStore, contractsStore } from '@src/stores'

  const { getBalance: getEvmBalance } = get(fn)
  let balance: string = ''

  const getBalance = async () => {
    try {
      balance = await getEvmBalance()
    } catch {
      balance = '0'
    }
  }

  $: $configStore.chainId && $providerStore && getBalance()

  let selected: string = '10'

  const goToPayment = () => {
    pageOptsStore.set({
      subtitle: `Purchase ${import.meta.env.PASSPORT_BRAND_STORED_VALUE_ALIAS}`,
      payments: {
        credenzaStoredValue: {
          disabled: true,
        },
      },
      tokens: [
        {
          contractAddress: $contractsStore.erc20Cred.address,
          amount: +selected,
        },
      ],
    })
    pageStore.set(Pages.PAYMENT)
  }
</script>

<Wallet
  bind:selected
  options={{
    title: `buy ${import.meta.env.PASSPORT_BRAND_STORED_VALUE_ALIAS}`,
    description: `Buy/Send ${import.meta.env.PASSPORT_BRAND_STORED_VALUE_ALIAS} for purchases throughout this website.`,
    onSendClick: () => pageStore.set(Pages.SEND_TOKENS),
    sendValueButtonText: `Send ${import.meta.env.PASSPORT_BRAND_STORED_VALUE_ALIAS}`,
    isSendValueDisabled: !balance,
    onPaymentClick: goToPayment,
  }}
/>
