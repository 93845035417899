<script lang="ts">
  import Container from "../shared/Container.svelte";
  import ContentHeader from "../shared/ContentHeader.svelte";
  import Button from "../shared/inputs/button/Button.svelte";

  export let options: {
    title: string;
    description: string;
    onConfirm: () => Promise<void>;
    onCancel: () => void;
  };

  let { title, description, onConfirm, onCancel } = options;
</script>

<ContentHeader text={title} {description} />

<Container class="crtw-flex crtw-justify-between crtw-gap-10">
  <Button text="Cancel" onClick={onCancel}></Button>
  <Button text="Sign the message" onClick={onConfirm} />
</Container>
