<script lang="ts">
  import { get } from 'svelte/store'
  import { isLoggingInProcessStore, fn, pageStore } from '@packages/stores'
  import { configStore, forcedPage } from '@src/stores'
  import { onDestroy } from 'svelte'
  import { Login } from '@packages/ui'
  import {
    AUTH_PROVIDERS,
    OAUTH_LOGIN_TYPE,
    OAUTH_PASSWORDLESS_TYPES,
    PassportLocalStorage,
  } from '@packages/utils/enums'
  import { generate } from 'lean-qr'
  import { toSvgSource } from 'lean-qr/extras/svg'
  import { generateAuthTypeImageHtml, getAuthMethodsConfigs } from './authMethods.helper'
  import { TAvailableLoginMethod } from '@packages/utils/types'

  const cachedQr = localStorage.getItem(PassportLocalStorage.QR_CODE)
  let qrCodeSvg: string
  const showQr = () => {
    if (!cachedQr) return
    const qrData = JSON.parse(cachedQr)
    const code = generate(JSON.stringify(qrData))
    qrCodeSvg = toSvgSource(code, { width: 250, height: 250 })
  }
  const hideQr = () => (qrCodeSvg = '')

  let config = get(configStore)
  let isLoading: boolean = false

  const { login, close } = get(fn)

  const handleLogin = async ({
    provider,
    oauthType,
    passwordlessType,
  }: {
    provider: AUTH_PROVIDERS
    oauthType?: OAUTH_LOGIN_TYPE
    passwordlessType?: OAUTH_PASSWORDLESS_TYPES
  }) => {
    try {
      isLoading = true
      await login(provider, { oauthType, passwordlessType })
      isLoading = false
      if (!$forcedPage && !$pageStore) close()
    } catch (err) {
      isLoading = false
    }
  }

  const getAvailableLoginMethods = (): TAvailableLoginMethod[] => {
    const methodConfigs = getAuthMethodsConfigs()
    const availableLoginMethods = Object.entries(methodConfigs)
      .filter(([_, config]) => (config.condition !== undefined ? config.condition : true))
      .map(([_, config]) => ({
        provider: AUTH_PROVIDERS.OAUTH,
        ...config,
      }))

    return availableLoginMethods
  }

  const loginMethods = getAvailableLoginMethods()

  const authTitle = config.content?.auth?.title ?? 'Let’s get started'
  const authDescription = config.content?.auth?.description ?? 'Confirm your account to connect.'

  const loginIndicatorSub = isLoggingInProcessStore.subscribe((val) => (isLoading = val))

  onDestroy(loginIndicatorSub)
</script>

<Login
  options={{
    isLoading,
    authTitle,
    authDescription,
    qrOptions: {
      qrCodeSvg,
      showQr,
      hideQr,
    },
    authOptions: {
      loginMethods,
      handleLogin,
      generateAuthTypeImageHtml,
    },
  }}
/>
