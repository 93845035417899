<style lang="scss">
  :global(.cpnav svg:focus) {
    outline: 0;
  }
</style>

<script lang="ts">
  import { get } from 'svelte/store'
  import { getIconAttrs } from '@lib/images/images'

  import PassportIcon from '@nav/images/passport.svg'
  import { NavContainer } from '@packages/ui'

  import { NavDirections, Themes } from '@packages/utils/enums'
  import type { TEvmContract, TNavConfig } from '@packages/utils/types'
  import { isLoggedInStore, fn } from '@packages/stores'
  import { ethers } from '@credenza3/core-web-evm-ext'
  import { onDestroy } from 'svelte'

  import { on, PassportEvents } from '@lib/events/events'
  import { analytics } from '@lib/mixpanel/mixpanel'
  import { getCryptoCurrencySymbol } from '@lib/strings/strings'
  import { getAddress } from '@src/passport/blockchain'
  import { configStore, providerStore } from '@src/stores'
  import { erc20CredTokens } from '@src/utils/config'
  import ExpandedNav from './components/ExpandedNav.svelte'

  export let styles: { [key: string]: string }

  export let navConfig: TNavConfig

  const config = get(configStore)
  const { openUI, getCREDContract, getBalance: getEvmBalance } = get(fn)

  let balance: string = ''

  const getBalance = async () => {
    try {
      const userAddress = await getAddress()
      if (!userAddress) return

      const { decimals, contract, address: contractAddress } = await getCREDContract() as TEvmContract
      const weiBalance = await getEvmBalance()
      const balanceResult = ethers.formatUnits(weiBalance, decimals)
      const symbol = await contract.symbol()
      balance = `(${balanceResult} ${getCryptoCurrencySymbol(symbol, import.meta.env.PASSPORT_BRAND_CRED_ALIAS)})`
      analytics.track('cp_nav_balance_read', {
        chain: $configStore.chainId,
        contract: contractAddress,
        decimals,
        balance: balanceResult,
        symbol,
      })
    } catch (e) {
      console.log(e)
      balance = ''
    }
  }

  const offRecheckBalanceListener = on(PassportEvents.RECHECK_BALANCE, async (txHashes: string[]) => {
    if (txHashes?.length) await Promise.all(txHashes.map((hash) => $providerStore.waitForTransaction(hash)))
    await getBalance()
  })

  const offUserAddressListener = on(PassportEvents.LOGIN, async () => {
    await getBalance()
  })

  $: $configStore.chainId && getBalance()

  const cssVarStyles = Object.entries(styles)
    .map(([key, value]) => `${key}:${value}`)
    .join(';')

  let isExpanded: boolean = false
  let isMinimized: boolean = navConfig.minimization?.enabled || false
  const navDirection = config.nav?.direction || NavDirections.TOP
  const theme = config.nav?.theme || Themes.WHITE

  let chevronStyles = ''
  $: {
    chevronStyles = Object.entries({
      // safari doesn't respect transform as a direct attribute
      ...(isExpanded ? { transform: 'scale(1, -1)' } : {}),
      ...getIconAttrs(theme),
    })
      .map(([key, value]) => `${key}:${value}`)
      .join(';')
  }

  const iconStyles = Object.entries(getIconAttrs(theme))
    .map(([key, value]) => `${key}:${value}`)
    .join(';')

  const loggedInStoreUnSub = isLoggedInStore.subscribe((isLoggedIn) => {
    if (!isLoggedIn) isExpanded = false
  })

  onDestroy(() => {
    offRecheckBalanceListener()
    offUserAddressListener()
    loggedInStoreUnSub()
  })
</script>

<NavContainer
  options={{
    isExpanded,
    theme,
    isMinimized,
    cssVarStyles,
    navConfig,
    navDirection,
    iconStyles,
    onItemClicked: (page) => openUI(page),
    expandedNavComponent: ExpandedNav,
  }}
  navItemOptions={{
    onClick: () => ($isLoggedInStore ? (isExpanded = !isExpanded) : openUI()),
    title: import.meta.env.PASSPORT_BRAND_NAV_NAME,
    subtitle: $isLoggedInStore ? undefined : import.meta.env.PASSPORT_BRAND_NAV_SUBTITLE || undefined,
    label: import.meta.env.PASSPORT_BRAND_LABEL,
    passIcon: PassportIcon,
    chevronStyles,
  }}
  balanceOptions={{
    isShown: config.buyTokens !== false && !!erc20CredTokens[config.chainId]?.address,
    balance,
  }}
></NavContainer>
