/* eslint-disable @typescript-eslint/no-unsafe-declaration-merging */
import packageJson from '@root/package.json'
import { get } from 'svelte/store'
import { ethers } from '@credenza3/core-web-evm-ext'
import './tailwind.scss'
import { handleTransactionInQuery } from '@src/Passport.service'
import {
  Chains,
  NetworkTypes,
  Pages,
  TransactionTypes,
  Themes,
  NavDirections,
  NavMinimizationTogglerPositions,
  ScanTypes,
} from '@packages/utils/enums'
import { PassportEvents } from '@lib/events/events.enums'
import { initAnalytics } from '@lib/mixpanel/mixpanel'
import AbstractPassport from '@packages/utils/classes/passport.abstract'
import type {
  TPassportConfig,
  TPassportConstructor,
  TChainId,
  TCredenzaContracts,
  TUser,
  TToastMessageOpts,
} from '@packages/utils/types'
import { accessTokenStore, userStore, isLoggedInStore, fn, ppaStore, currentPpaActionsStore } from '@packages/stores'
import {
  passportAuth,
  passportUi,
  passportInit,
  passportBlockchain,
  passportCommon,
  passportUser,
  passportEvents,
} from '@src/passport/index'
import { cloakStore, configStore, providerStore } from '@src/stores'

export class Passport implements AbstractPassport {
  static version = packageJson.version
  static chains = Chains
  static pages = Pages
  static paymentTypes = TransactionTypes
  static themes = Themes
  static navDirections = NavDirections
  static navMinimizationTogglerPositions = NavMinimizationTogglerPositions
  static scanTypes = ScanTypes
  static events = PassportEvents

  public accessToken: string | null = null
  public config: TPassportConfig<{ chainId: TChainId }>
  public isLoggedIn = false
  public user: TUser | null = null
  public ethers: typeof ethers
  public provider: ethers.BrowserProvider
  public chainId: TChainId
  public contracts: TCredenzaContracts
  public networkType: NetworkTypes.MAINNET | NetworkTypes.TESTNET

  constructor(opts: TPassportConstructor<{ chainId: TChainId }>) {
    this.destroy()
    if (opts?.chainId && !(<TChainId[]>Object.values(Chains)).includes(opts?.chainId)) {
      throw new Error('Unsupported ChainId. Check "Passport.chains" for available chains.')
    }
    this.ethers = ethers
    this.configurePassport(opts)
    initAnalytics(this.networkType)

    fn.set({
      // ui
      openUI: this.openUI,
      close: this.close,
      toastAlert: (message: string, type: 'success' | 'warning' | 'failure' | 'info', opts: TToastMessageOpts) => {
        if (this.networkType === NetworkTypes.MAINNET) return
        return this.toastAlert(message, type, opts)
      },
      // auth
      logout: this.logout,
      login: this.login.bind(this),
      // user
      updateProfile: this.updateProfile,
      confirmAccount: this.confirmAccount,
      // blockchain
      getCREDContract: this.getCREDContract,
      checkMembership: this.checkMembership,
      sendTokens: this.sendTokens,
      getBalance: this.getBalance,
      // common
      requestAirDrop: this.requestAirDrop,
      requestLoyaltyPoints: this.requestLoyaltyPoints,
    })

    userStore.subscribe((usr) => (this.user = usr))
    isLoggedInStore.subscribe((isLoggedIn) => {
      this.isLoggedIn = isLoggedIn || false
      handleTransactionInQuery(this.isLoggedIn, this.openUI)
    })
    accessTokenStore.subscribe((token) => (this.accessToken = token))
    providerStore.subscribe((provider) => (this.provider = provider))
    cloakStore.subscribe((enabled) => {
      if (enabled && typeof window !== 'undefined') void this.openUI()
    })
    ppaStore.subscribe(({ loading, active }) => {
      if (!get(configStore).content?.cloak || get(cloakStore) || typeof window === 'undefined') return
      if (active && loading) {
        return void this.openUI(Pages.LOADER, { loaderText: 'Checking your access...' })
      }
      if (active && !loading) {
        return void this.openUI(Pages.ERROR, {
          title: 'Premium Access Required',
          description: 'To access this page, please consider purchasing our content or subscribing to our service.',
          shouldRenderBackButton: false,
          primaryAction: get(currentPpaActionsStore)?.primaryAction,
          secondaryAction: get(currentPpaActionsStore)?.secondaryAction,
        })
      }
      this.close()
    })
  }

  login = passportAuth.login
  logout = passportAuth.logout

  openUI = passportUi.openUI
  close = passportUi.close
  toastAlert = passportUi.toastAlert
  destroy = passportUi.destroy
  hideNavigation = passportUi.hideNavigation
  showNavigation = passportUi.showNavigation

  configurePassport = passportInit.configurePassport
  init = passportInit.init

  getCREDContract = passportBlockchain.getCREDContract
  checkMembership = passportBlockchain.checkMembership
  sendTokens = passportBlockchain.sendTokens
  getWeb3Provider = passportBlockchain.getWeb3Provider
  getAddress = passportBlockchain.getAddress
  sendNft = passportBlockchain.sendNft
  switchChain = passportBlockchain.switchChain
  getBalance = passportBlockchain.getBalance

  updateProfile = passportUser.updateProfile
  confirmAccount = passportUser.confirmAccount
  getUser = passportUser.getUser

  requestAirDrop = passportCommon.requestAirDrop
  requestLoyaltyPoints = passportCommon.requestLoyaltyPoints
  getTokens = passportCommon.getTokens
  getRoles = passportCommon.getRoles
  getNfts = passportCommon.getNfts

  on = passportEvents.on
  once = passportEvents.once
}

export interface Passport {}
