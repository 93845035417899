<script lang="ts">
  import Container from "../shared/Container.svelte";
  import ContentHeader from "../shared/ContentHeader.svelte";
  import Button from "../shared/inputs/button/Button.svelte";

  export let options: {
    title: string;
    description: string;
    onClick: () => Promise<void>;
  };

  let { title, description, onClick } = options;
</script>

<ContentHeader text={title} {description} />

<Container>
  <Button text="Sign out now" {onClick} />
</Container>
