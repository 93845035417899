<script lang="ts">
  export let options: {
    qrCodeSvg: string;
    showQr: () => void;
    hideQr: () => void;
  };

  let { qrCodeSvg, showQr, hideQr } = options;
</script>

<div class="crtw-mt-4">
  <span class="crtw-text-sm">
    Cached QR is available.
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <span
      class="crtw-cursor-pointer crtw-text-warningTextColor"
      on:click={qrCodeSvg ? hideQr : showQr}
      role="button"
      tabindex="0"
    >
      {qrCodeSvg ? "Hide QR!" : "Show QR!"}
    </span>
  </span>

  {#if qrCodeSvg}
    <div>{@html qrCodeSvg}</div>
  {/if}
</div>
