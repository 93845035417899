<script lang="ts">
  import { Pages } from '@packages/utils/enums'
  import { onDestroy } from 'svelte'
  import { get } from 'svelte/store'
  import { ethers } from '@credenza3/core-web-evm-ext'

  import {
    pageOptsStore,
    userStore,
    pageStore,
    fn,
  } from '@packages/stores'
  import { DEFAULT_TOKEN_ID } from '@packages/utils/constants'
  import { dispatch, PassportEvents } from '@lib/events/events'
  import { TPaymentItem } from '@packages/utils/types'
  import {Payment} from '@packages/ui'
  import Stripe from './stripe/Stripe.svelte'
  import CredenzaBalance from './credenza-balance/CredenzaBalance.svelte'
  import { getAddress } from '@src/passport/blockchain'
  import { configStore, providerStore, contractsStore } from '@src/stores'

  const { close } = get(fn)
  const chainId = get(configStore).chainId

  $: if ($configStore.chainId !== chainId) close()

  let totalSumCents: number = 0
  let totalSumToken: number = 0
  let isPriceLoading: boolean = false
  let isLoading: boolean = false

  let pricesByToken: { [key: string]: number } = {}

  const getTokenPrice = async (sellableContract: ethers.Contract, tokenId: number): Promise<[number, number]> => {
    let priceCents = await sellableContract.getPriceFiat(tokenId).then((result) => Number(result))
    let priceToken = 0

    if (priceCents === 0) {
      ;[priceCents, priceToken] = await Promise.all([
        sellableContract.getPriceFiat(DEFAULT_TOKEN_ID).then((result) => Number(result)),
        sellableContract.getPriceToken(DEFAULT_TOKEN_ID).then((result) => Number(result)),
      ])
    } else {
      priceToken = await sellableContract.getPriceToken(tokenId).then((result) => Number(result))
    }

    return [priceCents, priceToken]
  }

  const calculateTotalPrice = async (items: TPaymentItem[]): Promise<void> => {
    try {
      isPriceLoading = true
      for (const token of items) {
        if (token.contractAddress === $contractsStore.erc20Cred.address) {
          totalSumCents += token.amount * 100
        } else {
          const { credenzaSellableMinAbi } = await import('@src/lib/abi')
          const sellableContract = new ethers.Contract(token.contractAddress, credenzaSellableMinAbi, $providerStore)
          const tokenId = token.tokenId ? token.tokenId : (token.typeId as string)

          let [priceCents, priceToken] = await getTokenPrice(sellableContract, +tokenId)

          if (token?.amount && token?.amount !== 0) {
            priceCents *= token.amount
            priceToken *= token.amount
          }

          totalSumCents += priceCents
          totalSumToken += priceToken

          pricesByToken[`${token.contractAddress}/${tokenId}`] = priceToken
        }
      }
    } catch (err) {
      dispatch(PassportEvents.ERROR, err)
    } finally {
      isPriceLoading = false
    }
  }

  const unsub = pageOptsStore.subscribe(async (store) => {
    if (!store?.tokens?.length && !store?.memberships?.length) return

    await calculateTotalPrice([...(store?.tokens || []), ...(store?.memberships || [])])
  })

  onDestroy(unsub)
</script>

{#if isLoading}
  {#await import('@components/progress/ProgressVideo.svelte') then module}
    <svelte:component this={module.default} />
  {/await}
{/if}

  <Payment bind:isLoading options={{
    userAddress: getAddress(),
    user: $userStore,
    title: $pageOptsStore.title || `Order Summary`,
    subtitle: $pageOptsStore.subtitle,
    onBackClick: () => pageStore.set(Pages.WALLET),
    isStripeDisabled: $pageOptsStore.payments?.stripe?.disabled,
    isCredezaValueDisabled: !totalSumCents || $pageOptsStore.payments?.credenzaStoredValue?.disabled,
    isPriceLoading,
    totalSumCents
  }}>
    <div slot="credit-card">
      <Stripe priceCents={totalSumCents} {isPriceLoading} purchaseConfig={$pageOptsStore} bind:isLoading />
    </div>
    <div slot="cred-balance">
      <CredenzaBalance
        {pricesByToken}
        totalPriceToken={totalSumToken}
        {isPriceLoading}
        purchaseConfig={$pageOptsStore}
        bind:isLoading
      />
    </div> 
  </Payment>  

