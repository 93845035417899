<script lang="ts">
  import { TUser } from "../../../utils/types";

  export let options: {
    storedValueAlias: string;
    balance?: string | null;
    userData: TUser | null;
    userAddress: Promise<string>;
  };

  let { storedValueAlias, userAddress } = options;
  $: balance = options.balance
  $: userData = options.userData
</script>

<div
  class="crtw-flex crtw-justify-center crtw-flex-col crtw-items-start crtw-gap-[22px] crtw-w-full crtw-px-2.5 crtw-py-0"
>
  {#if userData?.name}
    <div class="crtw-w-full crtw-break-all">
      <p class="crtw-font-medium">User name:</p>
      <p class="crtw-text-sm">
        {userData.name}
      </p>
    </div>
  {/if}
  {#if userData?.email || userData?.pending?.email}
    <div class="crtw-w-full crtw-break-all">
      <p class="crtw-font-medium">Email address:</p>
      <p class="crtw-text-sm">
        {#if userData?.email}
          {userData.email}
        {:else if userData?.pending?.email}
          {userData.pending?.email} (Unconfirmed)
        {/if}
      </p>
    </div>
  {/if}

  {#if userData?.phone || userData?.pending?.phone}
    <div class="crtw-w-full crtw-break-all">
      <p class="crtw-font-medium">Phone number:</p>
      <p class="crtw-text-sm">
        {#if userData?.phone}
          {userData.phone}
        {:else if userData?.pending?.phone}
          {userData.pending?.phone} (Unconfirmed)
        {/if}
      </p>
    </div>
  {/if}

  {#await userAddress then address}
    <div class="crtw-w-full crtw-break-all">
      <p class="crtw-font-medium">Logged in address:</p>
      <p class="crtw-text-sm">
        {address}
      </p>
    </div>
  {/await}
  

  {#if balance}
    <div class="crtw-w-full crtw-break-all">
      <p class="crtw-font-medium">{storedValueAlias} available:</p>
      <p class="crtw-text-sm">{balance}</p>
    </div>
  {/if}
</div>
