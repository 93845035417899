<script lang="ts">
  import ContentHeader from "../shared/ContentHeader.svelte";
  import Button from "../shared/inputs/button/Button.svelte";
  import ButtonGroup from "./ButtonGroup.svelte";

  export let selected: string;
  export let options: {
    title: string;
    description: string;
    onSendClick: () => void;
    sendValueButtonText: string;
    isSendValueDisabled: boolean;
    onPaymentClick: () => void;
  };

  let { title, description, onSendClick, sendValueButtonText, onPaymentClick } =
    options;

  $: isSendValueDisabled = options.isSendValueDisabled;
</script>

<div class="crtw-w-full crtw-px-2.5">
  <ContentHeader text={title} {description} />
  <ButtonGroup bind:selected />
  <div class="crtw-h-4" />
  <Button
    text="Confirm purchase"
    disabled={!selected}
    onClick={onPaymentClick}
  />
  <div class="crtw-m-3.5" />
  <Button
    onClick={onSendClick}
    text={sendValueButtonText}
    disabled={isSendValueDisabled}
  />
</div>
