<script lang="ts">
  import { get } from 'svelte/store'
  import { ethers } from '@credenza3/core-web-evm-ext'

  import { fn, forcedPageStore, pageStore, userStore } from '@packages/stores'
  import { Pages } from '@packages/utils/enums'

  import { getProfileLinks } from '@pages/profile/Profile.service'
  import { analytics } from '@lib/mixpanel/mixpanel'

  import { TEvmContract, TProfileLinks } from '@packages/utils/types'

  import { getGitbookAccessToken } from '@src/Passport.service'
  import { Profile } from '@packages/ui'
  import { getAddress } from '@src/passport/blockchain'
  import { configStore } from '@src/stores/config'

  const { getCREDContract, checkMembership } = get(fn)

  let links: TProfileLinks = {} as TProfileLinks
  let balance: string | null = null
  let gitbookToken: string | null = null

  const getProfileInfo = async () => {
    const publicKey = await getAddress()
    links = getProfileLinks(publicKey)
    try {
      const { isMember } = await checkMembership(publicKey)
      if (isMember) gitbookToken = await getGitbookAccessToken()
    } catch (err) {
      gitbookToken = null
    }

    if ($configStore.buyTokens !== false) {
      try {
        const { contract, decimals, address: contractAddress } = await getCREDContract() as TEvmContract
        const [balanceResult, symbol] = await Promise.all([
          contract.balanceOf(publicKey).then((weiBalance: bigint) => ethers.formatUnits(weiBalance, decimals)),
          contract.symbol(),
        ])
        balance = `${balanceResult} ${symbol}`
        analytics.track('cp_profile_balance_read', {
          chain: $configStore.chainId,
          contract: contractAddress,
          decimals,
          balance: balanceResult,
          symbol,
        })
      } catch (err) {
        balance = '-'
      }
    }
  }

  $: $configStore.chainId && getProfileInfo()
</script>

<Profile
  options={{
    links,
    gitbookToken,
    gitbookUrl: get(configStore).credenza.gitbookUrl,
    onSignOut: () => pageStore.set(Pages.LOGOUT),
    onUpdateProfile: () => forcedPageStore.set(Pages.UPDATE_PROFILE),
    balance,
    storedValueAlias: import.meta.env.PASSPORT_BRAND_STORED_VALUE_ALIAS,
    userData: $userStore,
    userAddress: getAddress(),
  }}
/>
