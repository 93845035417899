import { CredenzaSDK } from "@credenza3/core-web";
import { OAuthExtension } from "@credenza3/core-web-oauth-ext";
import { AccountExtension } from "@credenza3/core-web-account-ext";
import { NetworkTypes } from "../../enums";

export let sdk: CredenzaSDK;

export const initCredenzaSDK = async (
  clientId: string,
  extensions: unknown[],
  networkType: string = NetworkTypes.MAINNET
) => {
  if (sdk) return;

  sdk = new CredenzaSDK({
    env:
      networkType === NetworkTypes.MAINNET
        ? CredenzaSDK.SDK_ENV.PROD
        : CredenzaSDK.SDK_ENV.STAGING,
    clientId,
    extensions: [...extensions, new OAuthExtension(), new AccountExtension()],
  });

  const initSdkPromise = sdk.initialize();
  return initSdkPromise;
};
