<script lang="ts">
  import { onMount } from 'svelte'
  import { get } from 'svelte/store'
  import { pageOptsStore } from '@packages/stores'
  import { DEFAULT_LOADER_TITLE, DEFAULT_PROGRESS_VIDEO } from '@packages/utils/constants'
  import { isUrlValid } from '@lib/validation/validation'
  import { setVideoEnded } from '@components/progress/ProgressVideo.service'
  import { ProgressVideo } from '@packages/ui'
  import { configStore } from '@src/stores/config'

  const text: string = get(pageOptsStore)?.loaderText || DEFAULT_LOADER_TITLE
  const list = get(pageOptsStore)?.progressVideos ||
    get(configStore)?.content?.progressVideos || [DEFAULT_PROGRESS_VIDEO]

  let selected: {
    src: string
    embedded?: boolean
  } = DEFAULT_PROGRESS_VIDEO

  onMount(() => {
    const randomIndex = Math.floor(Math.random() * list.length)
    selected = isUrlValid(list[randomIndex].src) ? list[randomIndex] : DEFAULT_PROGRESS_VIDEO
  })
</script>

<ProgressVideo bind:isProgressFinished={$pageOptsStore.isProgressFinished} options={{
  text,
  selected,
  onSetVideoEnded: setVideoEnded,
}}/>
