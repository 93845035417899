export enum Chains {
  POLYGON = "137",
  POLYGON_AMOY = "80002",
  CHILIZ_SPICY = "88882",
  CHILIZ = "88888",
}

export enum NetworkTypes {
  TESTNET = "testnet",
  MAINNET = "mainnet",
}

export enum SuiNetworks {
  DEVNET = "devnet",
  TESTNET = "testnet",
  MAINNET = "mainnet",
}

export enum Pages {
  PROFILE = "profile",
  LOGOUT = "logout",
  LOGIN = "login",
  PASSPORT_ID = "passportId",
  WALLET = "wallet",
  UPDATE_PROFILE = "updateProfile",
  SEND_TOKENS = "sendTokens",
  CONFIRM_ACCOUNT = "confirmAccount",
  PAYMENT = "payment",
  PAYMENT_RESULT = "paymentResult",
  SCANNER = "scanner",
  ERROR = "error",
  LOADER = "loader",
  SIGN = "sign",
}

export enum TransactionTypes {
  STRIPE = "STRIPE",
  ERC20 = "ERC20",
}

export enum Themes {
  WHITE = "WHITE",
  BLACK = "BLACK",
}

export enum NavDirections {
  TOP = "TOP",
  BOTTOM = "BOTTOM",
}

export enum NavMinimizationTogglerPositions {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export enum PassportQueryParams {
  CREDENZA_USER_EMAIL = "credenza_user_email",
  CREDENZA_USER_GOOGLE_AWAIT = "cr_sg",
}

export enum ScanTypes {
  PASSPORT_ID = "PASSPORT_ID",
  TOKENS_PURCHASE = "TOKENS_PURCHASE",
  AIR_DROP = "AIR_DROP",
  REQUEST_LOYALTY_POINTS = "REQUEST_LOYALTY_POINTS",
  PASSPORT_ID_PASS = "PASSPORT_ID_PASS",
}

export enum RecieptTarget {
  EMAIL = "email",
  PHONE = "phone",
}

export enum PaymentViews {
  CREDIT_CARD = "CREDIT_CARD",
  CREDENZA_BALANCE = "CREDENZA_BALANCE",
}
