<script lang="ts">
  import { ethers } from '@credenza3/core-web-evm-ext'
  import { get } from 'svelte/store'
  import { type Scanner } from '@credenza3/scanner-lib'

  import { fn, pageOptsStore, pageStore } from '@packages/stores'
  import { dispatch, once, PassportEvents } from '@lib/events/events'
  import { Pages } from '@packages/utils/enums'
  import { getTxScanAddress } from '@src/lib/tx/tx'
  import { SendTokens } from '@packages/ui'
  import { getAddress } from '@src/passport/blockchain'
  import { configStore } from '@src/stores/config'
  import { TEvmContract } from '@packages/utils/types'
  import { providerStore } from '@src/stores'
  import { getUserAddressBySub } from '@src/Passport.service'

  const config = get(configStore)
  const { sendTokens, getCREDContract, toastAlert } = get(fn)

  let userAddress = ''
  let recipientAddress = ''
  let amount: number | undefined

  let activeCurrency = ''

  let decimals = 18
  let symbol = '-'
  let balance = ''

  let nativeDecimals = 18
  let nativeSymbol = '-'
  let nativeBalance = ''

  let isScannerHidden = true
  let isLoading = false

  let contract: ethers.Contract
  let signer: ethers.JsonRpcSigner
  let scanner: Scanner

  const onScannerClicked = async () => {
    const { Scanner } = await import('@credenza3/scanner-lib')
    scanner = new Scanner({ target: '#cpuiCredScanner' })
    once(PassportEvents.UI_CLOSED, scanner.close)
    await scanner.scan()
    scanner.on(
      Scanner.events.CAPTURE,
      async ({ sub }: { sub: string }) => {
        const { address } = await getUserAddressBySub(sub)
        recipientAddress = address
        scanner.close()
      },
    )
    scanner.on(Scanner.events.CANCEL, () => (isScannerHidden = true))
    scanner.on(Scanner.events.ERROR, (err) => {
      const message = err?.message || err
      if (message.includes('Permission'))
        alert('In order to scan please allow the current page to access your camera and try again.')
      isScannerHidden = true
    })
    isScannerHidden = false
  }

  const sendTokensToRecipient = async () => {
    const isTCRED = activeCurrency === symbol
    isLoading = true
    try {
      const weiBn = ethers.parseUnits(String(amount), isTCRED ? decimals : nativeDecimals)
      const sendParams = [recipientAddress, weiBn, ...(isTCRED ? [contract] : [])] as const
      const result = await sendTokens(...sendParams) as { hash: string, wait: () => Promise<void>}
      toastAlert(
        `<a href="${getTxScanAddress(result.hash)}" target="_blank">Send ${
          import.meta.env.PASSPORT_BRAND_STORED_VALUE_ALIAS
        } TX</a> was created`,
      )
      recipientAddress = ''
      amount = undefined
      void result
        .wait()
        .then(() => {
          toastAlert(
            `<a href="${getTxScanAddress(result.hash)}" target="_blank">Send ${
              import.meta.env.PASSPORT_BRAND_STORED_VALUE_ALIAS
            } TX</a> was mined`,
          )
          return Promise.all([contract.balanceOf(userAddress), signer.provider.getBalance(userAddress)])
        })
        .then(([weiBalance, weiNativeBalance]: [bigint, bigint]) => {
          balance = ethers.formatUnits(weiBalance, decimals)
          nativeBalance = parseFloat(ethers.formatUnits(weiNativeBalance, nativeDecimals)).toFixed(4)
          dispatch(PassportEvents.RECHECK_BALANCE, [result.hash])
        })
        .catch(() => {})
    } catch (err) {
      toastAlert(err?.message || err || 'An error occurred while sending tokens', 'failure')
      dispatch(PassportEvents.ERROR, { error: err })
    }
    isLoading = false
  }

  const init = async () => {
    const pageOpts = get(pageOptsStore)
    recipientAddress = pageOpts?.to || ''
    amount = pageOpts?.amount || undefined
    const { decimals: contractDecimals, contract: credContract } = await getCREDContract() as TEvmContract
    decimals = contractDecimals
    contract = credContract
    const provider = get(providerStore)
    signer = await provider.getSigner()
    userAddress = await getAddress()
    const results = await Promise.all([
      contract.symbol(),
      contract.balanceOf(userAddress),
      provider.getBalance(userAddress),
      fetch('https://chainid.network/chains.json')
        .then((result) => result.json())
        .then((networks) =>
          networks.find((network: { chainId: number }) => String(network.chainId) === config.chainId),
        ),
    ])
    symbol = results[0]
    balance = ethers.formatUnits(results[1], decimals)
    nativeDecimals = results[3]?.nativeCurrency?.decimals ?? 18
    nativeBalance = parseFloat(ethers.formatUnits(results[2], nativeDecimals)).toFixed(4)
    nativeSymbol = results[3]?.nativeCurrency?.symbol ?? 'ETH'
    activeCurrency = symbol
  }

  $: $configStore.chainId && $providerStore && init()
  $: isRecipientAddressValid = !!ethers.isAddress(recipientAddress)
  $: isSubmitDisabled =
    isLoading ||
    !amount ||
    !isRecipientAddressValid ||
    (activeCurrency === symbol && amount > +balance) ||
    (activeCurrency === nativeSymbol && amount > +nativeBalance)
</script>

<SendTokens
  bind:recipientAddress
  bind:amount
  options={{
    title: `Send ${import.meta.env.PASSPORT_BRAND_STORED_VALUE_ALIAS}`,
    isNativeCurrecyTransferAllowed: $configStore.transferTokens?.nativeCurrency !== false,
    isScannerHidden,
    balance,
    chainId: config.chainId,
    isRecipientAddressValid,
    onScannerClicked,
    onSendTokens: sendTokensToRecipient,
    onBackClicked: () => pageStore.set(Pages.WALLET),
    onChangeCurrency: () => (activeCurrency === symbol ? (activeCurrency = nativeSymbol) : (activeCurrency = symbol)),
    onSetMaxAmount: () => (activeCurrency === symbol ? (amount = +balance) : (amount = +nativeBalance)),
    isLoading,
    isSubmitDisabled,
    credAlias: import.meta.env.PASSPORT_BRAND_CRED_ALIAS,
    activeCurrency,
    symbol,
    nativeSymbol,
    nativeBalance,
  }}
/>
