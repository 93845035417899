<script lang="ts">
  import { Pages, Themes } from "@packages/utils/enums";
  import { MenuItem } from "@packages/ui";

  import SignoutIcon from "@packages/ui/images/signout.svg";
  import AccountIcon from "@packages/ui/images/account.svg";
  import QrCodeIcon from "@packages/ui/images/qrcode.svg";
  import ScanIcon from "@packages/ui/images/scanner.svg";
  import BuyTokenIcon from "@packages/ui/images/buyToken.svg";

  import type { TExpandedNavProps } from "@packages/utils/types";

  export let options: TExpandedNavProps;

  let { theme, iconStyles, onClick } = options;

  $: balance = options.balance;
  $: isShownBalance = options.isShownBalance;
  $: isMinimized = options.isMinimized;
</script>

<MenuItem
  options={{
    isMinimized,
    theme,
    title: "Sign Out",
    onClick: () => onClick(Pages.LOGOUT),
  }}
>
  <img src={SignoutIcon} style={iconStyles} slot="icon" alt="" />
</MenuItem>
<MenuItem
  options={{
    isMinimized,
    theme,
    title: "Show ID",
    onClick: () => onClick(Pages.PASSPORT_ID),
  }}
>
  <img src={QrCodeIcon} style={iconStyles} slot="icon" alt="" />
</MenuItem>
<MenuItem
  options={{
    isMinimized,
    theme,
    title: "PassScan",
    onClick: () => onClick(Pages.SCANNER),
  }}
>
  <img src={ScanIcon} style={iconStyles} slot="icon" alt="" />
</MenuItem>

{#if isShownBalance}
  <MenuItem
    options={{
      theme,
      title: "Wallet",
      onClick: () => onClick(Pages.WALLET),
      isMinimized,
    }}
  >
    <img src={BuyTokenIcon} style={iconStyles} slot="icon" alt="" />

    <span
      class="cpnav-menu-item-balance crtw-text-[9px]"
      class:cpnav-menu-item-balance-white={theme === Themes.BLACK}
      >{balance}</span
    >
  </MenuItem>
{/if}

<MenuItem
  options={{
    isMinimized,
    theme,
    title: "My Account",
    onClick: () => onClick(Pages.PROFILE),
  }}
>
  <img src={AccountIcon} style={iconStyles} slot="icon" alt="" />
</MenuItem>
