<script lang="ts">
  import { TUser } from "../../../utils/types";
  import ContentHeader from "../shared/ContentHeader.svelte";
  import ButtonLink from "../shared/inputs/button-link/ButtonLink.svelte";
  import Button from "../shared/inputs/button/Button.svelte";
  import NewWindowIcon from "../../images/new-window.svg";
  import GitbookIcon from "../../images/gitbook-logo.svg";
  import AccountIcon from "../../images/account.svg";
  import UserInfo from "../shared/UserInfo.svelte";

  export let options: {
    links: {
      passportLink?: string;
      scanLink?: string;
      scanIcon?: string;
      scanName?: string;
      nftsLink?: string;
      nftsIcon?: string;
    };
    gitbookUrl: string;
    gitbookToken: string | null;
    onSignOut: () => void;
    onUpdateProfile: () => void;

    storedValueAlias: string;
    balance: string | null;
    userData: TUser | null;
    userAddress: Promise<string>;
  };

  let {
    gitbookToken,
    onSignOut,
    onUpdateProfile,
    gitbookUrl,
    userAddress,
    storedValueAlias,
  } = options;

  $: links = options.links;
  $: balance = options.balance;
  $: userData = options.userData;
</script>

<ContentHeader text="Account Details" />
<div class="crtw-flex crtw-flex-col crtw-w-full crtw-mt-9 crtw-gap-9">
  <UserInfo options={{ userAddress, userData, storedValueAlias, balance }} />
  <div
    class="crtw-flex crtw-flex-col crtw-w-full crtw-items-center crtw-gap-2.5"
  >
    {#if links?.passportLink}
      <a
        href={links.passportLink}
        target="_blank"
        title="View on Passport"
        class="crtw-flex crtw-items-center crtw-justify-start crtw-w-full crtw-border crtw-border-gray-300 crtw-p-2.5 crtw-no-underline crtw-text-gray-600"
        rel="noreferrer"
      >
        <img class="crtw-h-7 crtw-mr-14" src={AccountIcon} alt="" />
        <div class="crtw-flex crtw-w-full crtw-justify-between">
          <span>View on Passport</span>
          <img src={NewWindowIcon} alt="" />
        </div>
      </a>
    {/if}
    {#if links?.scanLink && links?.scanIcon}
      <a
        href={links.scanLink}
        target="_blank"
        title="View in the block explorer"
        class="crtw-flex crtw-items-center crtw-justify-start crtw-w-full crtw-border crtw-border-gray-300 crtw-p-2.5 crtw-no-underline crtw-text-gray-600"
        rel="noreferrer"
      >
        <img class="crtw-h-7 crtw-mr-14" src={links.scanIcon} alt="" />
        <div class="crtw-flex crtw-w-full crtw-justify-between">
          <span>View in {links.scanName || "Etherscan"}</span>
          <img src={NewWindowIcon} alt="" />
        </div>
      </a>
    {/if}
    {#if links?.nftsLink && links?.nftsIcon}
      <a
        href={links.nftsLink}
        target="_blank"
        title="View assets"
        class="crtw-flex crtw-items-center crtw-justify-start crtw-w-full crtw-border crtw-border-gray-300 crtw-p-2.5 crtw-no-underline crtw-text-gray-600"
        rel="noreferrer"
      >
        <img class="crtw-h-7 crtw-mr-14" src={links.nftsIcon} alt="" />
        <div class="crtw-flex crtw-w-full crtw-justify-between">
          <span>View assets on OpenSea</span>
          <img src={NewWindowIcon} alt="" />
        </div>
      </a>
    {/if}
    {#if gitbookToken}
      <a
        href={`${gitbookUrl}?jwt_token=${gitbookToken}`}
        target="_blank"
        title="GitBook docs"
        class="crtw-flex crtw-items-center crtw-justify-start crtw-w-full crtw-border crtw-border-gray-300 crtw-p-2.5 crtw-no-underline crtw-text-gray-600"
        rel="noreferrer"
      >
        <img class="crtw-h-7 crtw-mr-14" src={GitbookIcon} alt="" />
        <div class="crtw-flex crtw-w-full crtw-justify-between">
          <span>GitBook docs</span>
          <img src={NewWindowIcon} alt="" />
        </div>
      </a>
    {/if}
  </div>
  <div
    class="crtw-flex crtw-flex-col crtw-justify-center crtw-items-center crtw-gap-4"
  >
    <ButtonLink text="Update your account details" onClick={onUpdateProfile} />
    <Button text="Sign Out" onClick={onSignOut} />
  </div>
</div>
