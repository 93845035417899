<script lang="ts">
  import { ethers } from '@credenza3/core-web-evm-ext'
  import { get } from 'svelte/store'
  import { onMount } from 'svelte'
  import { Pages } from '@packages/utils/enums'
  import { fn, pageOptsStore, pageStore, userStore } from '@packages/stores'
  import { dispatch, PassportEvents } from '@lib/events/events'
  import { TransactionTypes } from '@packages/utils/enums'
  import { sendContractTx, getTxScanAddress } from '@src/lib/tx/tx'
  import { requestReceipt } from '@pages/payment/Payment.service'
  import { analytics } from '@lib/mixpanel/mixpanel'
  import { waitVideoEnded } from '@components/progress/ProgressVideo.service'
  import { TEvmContract, TPaymentItem } from '@packages/utils/types'
  import { getCryptoCurrencySymbol } from '@lib/strings/strings'
  import { CredenzaBalance } from '@packages/ui'
  import { getAddress } from '@src/passport/blockchain'
  import { configStore, providerStore } from '@src/stores'

  export let totalPriceToken: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  export let purchaseConfig: any
  export let isLoading: boolean
  export let isPriceLoading: boolean
  export let pricesByToken: { [key: string]: number }

  const { getCREDContract, toastAlert } = get(fn)
  const { chainId, clientId } = get(configStore)

  let balance: bigint
  let formattedBalance: string
  let symbol: string = ''
  let contract: ethers.Contract
  let decimals: number
  let userAddress: string
  let signer: ethers.Signer
  let credContract: {
    address: string
    decimals: number
    contract: ethers.Contract
  }

  const processPaymentItem = async (item: TPaymentItem, index: number) => {
    const { credenzaSellableMinAbi } = await import('@src/lib/abi')
    const credenzaSellableContract = new ethers.Contract(item.contractAddress, credenzaSellableMinAbi, signer)
    const tokenOrTypeId = item.tokenId ? item.tokenId : (item.typeId as string)
    const priceToken = pricesByToken[`${item.contractAddress}/${tokenOrTypeId}`]

    const approveTx = await contract.approve.populateTransaction(
      await credenzaSellableContract.getAddress(),
      priceToken,
    )

    const { nonce: approveResultNonce } = await sendContractTx(approveTx, contract)
    const buyTx = await credenzaSellableContract.buyWithToken.populateTransaction(
      tokenOrTypeId,
      item.amount || 1,
      userAddress,
      { nonce: approveResultNonce + 1 },
    )

    const result = await sendContractTx(buyTx, credenzaSellableContract)
    void requestReceipt({ hash: result.hash, title: purchaseConfig.title, subtitle: purchaseConfig.subtitle })
    toastAlert(`<a href="${getTxScanAddress(result.hash)}" target="_blank">Purchase TX #${index}</a> was created`)
    dispatch(PassportEvents.PAYMENT, { type: TransactionTypes.ERC20, data: result })

    await result.wait()
    toastAlert(`<a href="${getTxScanAddress(result.hash)}" target="_blank">Purchase TX #${index}</a> was mined`)
    dispatch(PassportEvents.RECHECK_BALANCE)
  }

  const payWithCredenza = async () => {
    try {
      if (BigInt(totalPriceToken) > balance) return

      isLoading = true
      const paymentPromises = [...(purchaseConfig.tokens || []), ...(purchaseConfig.memberships || [])].map(
        (item, index) => processPaymentItem(item, index + 1),
      )
      await Promise.all(paymentPromises)

      const user = get(userStore)
      if (user) {
        analytics.track('cp_payment_stored_value', {
          $email: user.email,
          $phone: user.phone,
          chain: chainId,
          clientId,
          address: userAddress,
          location: window.location.href,
          tokens: purchaseConfig.tokens,
          memberships: purchaseConfig.memberships,
          total: totalPriceToken,
          description: (purchaseConfig?.title ?? '') + `(${purchaseConfig?.subtitle ?? ''})`,
        })
      }

      await waitVideoEnded()

      if (totalPriceToken === 0) pageOptsStore.set({ subtitle: 'Your claim was successful!', receipt: false })
      pageStore.set(Pages.PAYMENT_RESULT)
    } catch (err) {
      toastAlert(`Payment error: ${err.message || err}`, 'failure')
      pageOptsStore.set({ errorMessage: err.message })
      pageStore.set(Pages.ERROR)
      dispatch(PassportEvents.ERROR, { error: err })
    } finally {
      isLoading = false
    }
  }

  onMount(async () => {
    try {
      credContract = (await getCREDContract()) as TEvmContract

      signer = await get(providerStore).getSigner()
      contract = credContract.contract
      decimals = credContract.decimals
      ;[userAddress, symbol] = await Promise.all([getAddress(), contract.symbol()])
      balance = await contract.balanceOf(userAddress)

      formattedBalance = `${ethers.formatUnits(BigInt(balance) - BigInt(totalPriceToken), decimals)} ${getCryptoCurrencySymbol(symbol, import.meta.env.PASSPORT_BRAND_CRED_ALIAS)}`
    } catch (err) {
      console.log(err)
    }
  })
</script>

<CredenzaBalance
  options={{
    formattedBalance,
    totalPriceToken,
    balance,
    credAlias: `${import.meta.env.PASSPORT_BRAND_CRED_ALIAS}`,
    onPay: payWithCredenza,
    onGetCred: () => pageStore.set(Pages.WALLET),
    totalToPay: (+ethers.formatUnits(totalPriceToken, decimals)).toFixed(2),
    isLoading,
    isPaymentDisabled: !Number(balance) || BigInt(totalPriceToken) > balance || isPriceLoading,
  }}
/>
