<script lang="ts">
  export let options: {
    title: string;
    description: string | undefined;
    subtitle: string;
  };

  let { title, description, subtitle } = options;
</script>

<div>
  <div
    class="crtw-text-black-900 crtw-text-xl md:crtw-text-2xl crtw-font-semibold crtw-pt-5 crtw-mb-3.5 crtw-text-center"
  >
    {title}
  </div>
  <div class="crtw-mb-3.5 crtw-text-sm crtw-text-justify">
    {subtitle}
  </div>
  <div class="crtw-mb-3.5 crtw-text-sm crtw-text-justify">
    {#if description}
      {description}
    {:else}
      Typically, your purchase will be processed within a few seconds. In the
      event of unusually high traffic, you may have to wait as much as a minute
      or so. If several minutes pass and you are not seeing your purchase,
      please contact
      <a class="crtw-underline" href="mailto:support@credenza3.com"
        >support@credenza3.com</a
      >.
    {/if}
  </div>
</div>
