<script lang="ts">
  import { SvelteComponent } from "svelte";
  import {
    Themes,
    NavMinimizationTogglerPositions,
    NavDirections,
    Pages,
  } from "../../utils/enums";
  import { TExpandedNavProps, TNavConfig, TTheme } from "../../utils/types";
  import MainNavItem from "./MainNavItem.svelte";
  import MinimizationToggler from "./MinimizationToggler.svelte";

  export let options: {
    isExpanded: boolean;
    theme: TTheme;
    isMinimized: boolean;
    cssVarStyles: string;
    navConfig: TNavConfig;
    navDirection: string;
    iconStyles: string;
    onItemClicked: (page: Pages) => void;
    expandedNavComponent: typeof SvelteComponent<{ options: TExpandedNavProps }>
  };
  export let navItemOptions: {
    onClick: () => void;
    title: string;
    subtitle: string;
    label: string;
    passIcon: string;
    chevronStyles: string;
  };

  export let balanceOptions: {
    isShown: boolean;
    balance: string;
  };
  let {
    theme,
    cssVarStyles,
    navConfig,
    navDirection,
    iconStyles,
    onItemClicked,
    isMinimized,
    expandedNavComponent
  } = options;

  $: isExpanded = options.isExpanded;

  $: balance = balanceOptions.balance;
  $: isShownBalance = balanceOptions.isShown;
  $: chevronStyles = navItemOptions.chevronStyles;
</script>

<div class="crdz-ui" id="cpnav">
  <div
    class="cpnav crtw-fixed crtw-z-50 crtw-w-56 crtw-flex crtw-flex-col crtw-items-center crtw-justify-center crtw-bg-white crtw-text-gray-800
    crtw-font-[poppins] crtw-text-xs crtw-border crtw-border-gray-700 crtw-rounded crtw-cursor-pointer crtw-pr-1.5"
    class:crtw-bg-black={theme === Themes.BLACK}
    class:crtw-text-white={theme === Themes.BLACK}
    class:crtw-w-[70px]={isMinimized}
    class:crtw-pr-5={navConfig.minimization?.toggler?.enabled &&
      navConfig.minimization?.toggler?.position ===
        NavMinimizationTogglerPositions.RIGHT}
    class:crtw-pl-5={navConfig.minimization?.toggler?.enabled &&
      navConfig.minimization?.toggler?.position !==
        NavMinimizationTogglerPositions.RIGHT}
    style={cssVarStyles}
  >
    <MinimizationToggler {theme} {navConfig} bind:isMinimized />

    {#if navDirection === NavDirections.TOP && isExpanded}
      <svelte:component this={expandedNavComponent} options={{
          isMinimized,
          theme,
          iconStyles,
          onClick: onItemClicked,
          isShownBalance,
          balance,
        }}
      />
    {/if}

    <MainNavItem
      options={{ ...navItemOptions, theme, isMinimized, chevronStyles }}
    />

    {#if navDirection === NavDirections.BOTTOM && isExpanded}
      <svelte:component this={expandedNavComponent} options={{
          isMinimized,
          theme,
          iconStyles,
          onClick: onItemClicked,
          isShownBalance,
          balance,
        }}
      />
    {/if}
  </div>
</div>
