<script lang="ts">
  export let value: number;
  export let placeholder: string = "Code";
</script>

<div class="crtw-flex crtw-justify-center">
  <input
    type="number"
    min="0"
    max="99999"
    class="crtw-w-full crtw-mb-1.5 phone crtw-border crtw-rounded crtw-px-2 crtw-outline-none crtw-mt-2.5 crtw-h-[50px] crtw-text-xs"
    {placeholder}
    bind:value
    name="code"
  />
</div>

<style>
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
  }
</style>
