<script lang="ts">
  import ContentHeader from "../shared/ContentHeader.svelte";
  import Loader from "../shared/Loader.svelte";

  export let options: {
    isScannerLoaded: boolean
  }

  $: isScannerLoaded = options.isScannerLoaded
</script>

<ContentHeader text="Scan QR code" />
<div class="crtw-w-full">
  {#if !isScannerLoaded}
    <div
      class="crtw-w-full crtw-h-64 crtw-rounded-[10px] crtw-flex crtw-items-center crtw-justify-center"
      style="background-color: rgb(238, 238, 238)"
    >
      <Loader />
    </div>
  {/if}
  <div
    id="cpuiScanner"
    class="crtw-w-full crtw-h-64 crtw-rounded-lg crtw-flex crtw-items-center crtw-justify-center"
    class:crtw-hidden={!isScannerLoaded}
    style="background-color: rgb(238, 238, 238)"
  />
</div>
