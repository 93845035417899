<script lang="ts">
  import { TAvailableLoginMethod } from "../../../../utils/types";
  import Container from "../../shared/Container.svelte";
  import Loader from "../../shared/Loader.svelte";
  import AuthButton from "./AuthButton.svelte";
  import SocialAuthButton from "./SocialAuthButton.svelte";

  export let options: {
    loginMethods: TAvailableLoginMethod[];
    handleLogin: (method: TAvailableLoginMethod) => void;
    generateAuthTypeImageHtml: (type: string) => string;
    isLoading: boolean;
  };

  let { loginMethods, handleLogin, isLoading, generateAuthTypeImageHtml } =
    options;
</script>

{#if !isLoading}
  <Container class="crtw-mb-16">
    {#each loginMethods.slice(0, 2) as method}
      <AuthButton handler={() => handleLogin(method)}>
        Continue with {method.text || method.oauthType}
      </AuthButton>
    {/each}

    {#if loginMethods.length > 2}
      <div
        class="crtw-flex crtw-mt-12 crtw-mb-7 crtw-items-center crtw-justify-center"
      >
        <div class="crtw-border-b crtw-border-gray-300 crtw-w-[21%]" />
        <span class="crtw-text-gray-400 crtw-text-[11px] crtw-mx-[7px]">or</span
        >
        <div class="crtw-border-b crtw-border-gray-300 crtw-w-1/4" />
      </div>
    {/if}

    <div class="crtw-flex crtw-items-center crtw-justify-center crtw-gap-4">
      {#each loginMethods.slice(2) as method}
        <SocialAuthButton handler={() => handleLogin(method)}>
          {@html generateAuthTypeImageHtml(
            method.passwordlessType || method.oauthType || method.provider
          )}
        </SocialAuthButton>
      {/each}
    </div>
  </Container>
{:else}
  <div class="crtw-m-5">
    <Loader />
  </div>
{/if}
