import {
  ENVS,
  connect,
  disconnect,
  request,
} from "@credenza-web3/async-client";
import { NetworkTypes } from "../../enums";
import { accessTokenStore } from "../../../stores";
import { get } from "svelte/store";

const connectWsClient = async (networkType: string) => {
  try {
    await connect({
      accessToken: `Bearer ${get(accessTokenStore)}`,
      env: networkType === NetworkTypes.TESTNET ? ENVS.TEST : ENVS.MAIN,
      debug: networkType === NetworkTypes.TESTNET,
    });
  } catch (err) {
    console.error(err);
  }
};

export const requestWs = async <T>(
  networkType: string,
  channel: string,
  payload: unknown
) => {
  try {
    await connectWsClient(networkType);
    const { payload: response } = await request({ channel, payload });
    return response as T;
  } catch (err) {
    throw new Error(
      err.error?.message || "Unknown error occurred. Please try again."
    );
  } finally {
    await disconnect();
  }
};
