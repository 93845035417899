import merge from 'lodash.merge'
import { EvmExtension } from '@credenza3/core-web-evm-ext'

import { config } from '@packages/utils/config'
import { retrieveCredenzaAccess } from '@src/Passport.service'
import { isLoggedInStore } from '@packages/stores'
import { PassportEvents, dispatch } from '@lib/events/events'
import { createPayPeyArticleObserver } from '@src/lib/observers/ppa'

import type { Passport } from '@src/Passport'
import type { TChainId, TPassportConstructor } from '@packages/utils/types'
import { get } from 'svelte/store'
import { initCredenzaSDK, sdk } from '@lib/sdk/sdk'
import { getNetworkType } from '@packages/utils/chains'
import { getChainConfig } from '@packages/utils/lib/sdk/chain.config'
import { analytics } from '@packages/utils/lib/mixpanel/mixpanel'
import { configStore } from '@src/stores/config'
import { contractsStore } from '@src/stores'
import { erc20CredTokens, membershipContracts } from '@src/utils/config'

export async function configurePassport(this: Passport, opts: TPassportConstructor<{ chainId: TChainId }>) {
  this.networkType = getNetworkType(opts.chainId)
  this.config = {
    ...merge(config[this.networkType], opts.config || {}),
    chainId: opts.chainId,
    clientId: opts.clientId,
    networkType: this.networkType,
  }
  this.chainId = this.config.chainId
  this.contracts = {
    erc20Cred: erc20CredTokens[this.config.chainId],
    membership: membershipContracts[this.config.chainId],
  }
  contractsStore.set(this.contracts)
  configStore.set(this.config)
}

export async function init(this: Passport) {
  try {
    const { chainId, auth, clientId } = get(configStore)
    const chainConfig = getChainConfig(chainId)

    const evmExtensions: unknown[] = []
    if (auth?.metamask !== false && !!(window as any).ethereum) {
      const { MetamaskExtension } = await import('@credenza3/core-web-evm-metamask-ext')
      evmExtensions.push(new MetamaskExtension())
    }

    const extensions = [new EvmExtension({ chainConfig, extensions: evmExtensions })]

    const initPromise = initCredenzaSDK(clientId, extensions, this.networkType)
    sdk.on(PassportEvents.LOGIN, async () => await onSdkLogin(initPromise))
    await initPromise

    if (!sdk.getAccessToken()) return
    await retrieveCredenzaAccess()
    createPayPeyArticleObserver()
  } catch (_) {
    /** */
  }
  dispatch(PassportEvents.INIT)
  if (get(isLoggedInStore)) this.close()
}

const onSdkLogin = async (initSdkPromise: Promise<void>) => {
  await initSdkPromise
  const user = await sdk.account.info()
  const provider = await sdk.evm.getEthersProvider()
  const signer = await provider.getSigner()
  const { chainId, clientId } = get(configStore)
  void analytics.track('cp_login', {
    $email: user.email,
    $phone: user.phone,
    chain: chainId,
    clientId,
    provider,
    address: await signer.getAddress(),
    location: window.location.href,
  })
  dispatch(PassportEvents.LOGIN, { provider })
}
