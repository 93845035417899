<script lang="ts">
  import { PaymentViews } from '../../../utils/enums';
  import ButtonBack from '../shared/inputs/button-back/ButtonBack.svelte';
  import LockIcon from '../../images/lock.svg'
  import { TUser } from '../../../utils/types';
  import PaymentSwitchButton from './PaymentSwitchButton.svelte';

  export let isLoading: boolean
  export let options: {
    userAddress: Promise<string>
    user: TUser| null
    title: string
    subtitle?: string
    onBackClick: () => void
    isStripeDisabled: boolean
    isCredezaValueDisabled: boolean,
    isPriceLoading: boolean
    totalSumCents: number
  }

  let activeTab = PaymentViews.CREDIT_CARD

  let { userAddress, user, title, subtitle, onBackClick, isStripeDisabled } = options

  $: isPriceLoading = options.isPriceLoading
  $: totalSumCents = options.totalSumCents
  $: isCredezaValueDisabled = options.isCredezaValueDisabled
</script>

<div class="cpui-payment crtw-text-left crtw-w-full" class:crtw-hidden={isLoading}>
  <div class="crtw-text-right crtw-text-[13px] crtw-text-gray-400 crtw-mb-6">
    {#await userAddress then address}
      <div>
        {address}
      </div>
    {/await}
    
    <div>{user?.email || user?.pending?.email || ''}</div>
  </div>
  <div class="crtw-font-bold crtw-text-xl">{title}</div>
  <div class="crtw-flex crtw-justify-between crtw-mb-3 crtw-text-gray-600 crtw-font-bold">
    {#if subtitle}
      <div>{subtitle}</div>
    {/if}
    {#if isPriceLoading}
      <div>Getting price...</div>
    {:else if totalSumCents}
      <div>${(+totalSumCents / 100).toFixed(2)}</div>
    {/if}
  </div>
  <div class="crtw-flex crtw-bg-gray-200 crtw-rounded-md crtw-p-px">
    <PaymentSwitchButton
      text="Credit Card"
      onClick={() => (activeTab = PaymentViews.CREDIT_CARD)}
      isActive={activeTab === PaymentViews.CREDIT_CARD}
      disabled={isStripeDisabled}
    />
    <PaymentSwitchButton
      text="Credenza Balance"
      onClick={() => (activeTab = PaymentViews.CREDENZA_BALANCE)}
      isActive={activeTab === PaymentViews.CREDENZA_BALANCE}
      disabled={isCredezaValueDisabled}
    />
  </div>
  <hr class="crtw-block crtw-border-t-2 crtw-border-gray-200 crtw-w-1/2 crtw-mx-auto crtw-mt-3.5 crtw-mb-[30px]" />
  {#if activeTab === PaymentViews.CREDIT_CARD}
    <slot name="credit-card" />
  {:else}
    <slot name="cred-balance" />
  {/if}
  <div class="crtw-flex crtw-w-full crtw-justify-center crtw-items-center">
    <img src={LockIcon} alt="" /><span class="crtw-text-sm crtw-text-gray-400 crtw-ml-1"
      >Payments are secure and encrypted</span
    >
  </div>
  <div class="crtw-mt-1.5">
    <ButtonBack onClick={onBackClick} />
  </div>
</div>
