<script lang="ts">
  import { get } from 'svelte/store'
  import { fn } from '@packages/stores'
  import { Logout } from '@packages/ui'
  import { configStore } from '@src/stores/config'

  const config = get(configStore)
  const { logout, close } = get(fn)

  const executeLogout = async () => {
    logout()
    close()
  }
</script>

<Logout
  options={{
    title: config.content?.logout?.title || 'Sign out',
    description:
      config.content?.logout?.description ||
      'This will sign you out and you will be asked to verify your identity next time you sign in.',
    onClick: executeLogout,
  }}
/>
