<style>
  .active {
    background-color: #ffff;
    font-weight: 500;
    color: #000;
  }
  .btn-disabled {
    pointer-events: none;
    background-color: #d0d2d5;
  }
</style>

<script lang="ts">
  import Loader from '../shared/Loader.svelte'
  
  export let onClick: (...args: unknown[]) => unknown
  export let text: string
  export let disabled: boolean = false
  export let isLoading: boolean = false
  export let isActive: boolean = false
</script>

<button
  type="submit"
  class="crtw-flex crtw-justify-center crtw-items-center crtw-w-full crtw-h-[50px] crtw-cursor-pointer crtw-border crtw-border-gray-300
  crtw-rounded crtw-font-['poppins'] crtw-text-gray-600"
  class:btn-disabled={disabled || isLoading}
  class:active={isActive}
  on:click|preventDefault={onClick}
>
  {#if isLoading}
    <Loader />
  {:else}
    {text}
  {/if}
</button>