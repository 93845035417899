<script lang="ts">
  import { get } from 'svelte/store'
  import { fn, pageStore, pageOptsStore, ppaStore } from '@packages/stores'
  import { dispatch, PassportEvents } from '@lib/events/events'

  import { Pages } from '@packages/utils/enums'
  import { MainApp } from '@packages/ui'
  import { cloakStore, forcedPage } from '@src/stores'

  $: shouldBlurBackdrop = $cloakStore || $ppaStore.active
  $: isHeaderShown = !$cloakStore && !$ppaStore.active && !$ppaStore.loading
  const handleCloseClick = () => {
    const { close } = get(fn)

    dispatch(PassportEvents.UI_CLOSED, {
      page: <string>(get(forcedPage) || get(pageStore)),
      pageOpts: get(pageOptsStore),
    })
    close()
  }
</script>

<MainApp
  bind:shouldBlurBackdrop
  bind:isHeaderShown
  onCloseClick={handleCloseClick}
>
  {#if $forcedPage === Pages.LOGIN || $pageStore === Pages.LOGIN}
    {#await import('@pages/login/Login.svelte') then module}
      <svelte:component this={module.default} />
    {/await}
  {:else if $pageStore === Pages.LOGOUT}
    {#await import('@pages/logout/Logout.svelte') then module}
      <svelte:component this={module.default} />
    {/await}
  {:else if $forcedPage === Pages.CONFIRM_ACCOUNT || $pageStore === Pages.CONFIRM_ACCOUNT}
    {#await import('@pages/confirm-account/ConfirmAccount.svelte') then module}
      <svelte:component this={module.default} />
    {/await}
  {:else if $forcedPage === Pages.UPDATE_PROFILE || $pageStore === Pages.UPDATE_PROFILE}
    {#await import('@pages/update-profile/UpdateProfile.svelte') then module}
      <svelte:component this={module.default} />
    {/await}
  {:else if $pageStore === Pages.PASSPORT_ID}
    {#await import('@pages/passport-id/PassportId.svelte') then module}
      <svelte:component this={module.default} />
    {/await}
  {:else if $pageStore === Pages.SIGN}
    {#await import('@pages/sign/Sign.svelte') then module}
      <svelte:component this={module.default} />
    {/await}
  {:else if $pageStore === Pages.WALLET}
    {#await import('@pages/wallet/Wallet.svelte') then module}
      <svelte:component this={module.default} />
    {/await}
  {:else if $pageStore === Pages.SEND_TOKENS}
    {#await import('@pages/send-tokens/SendTokens.svelte') then module}
      <svelte:component this={module.default} />
    {/await}
  {:else if $pageStore === Pages.PAYMENT}
    {#await import('@pages/payment/Payment.svelte') then module}
      <svelte:component this={module.default} />
    {/await}
  {:else if $pageStore === Pages.PAYMENT_RESULT}
    {#await import('@pages/payment-result/PaymentResult.svelte') then module}
      <svelte:component this={module.default} />
    {/await}
  {:else if $pageStore === Pages.SCANNER}
    {#await import('@pages/scanner/Scanner.svelte') then module}
      <svelte:component this={module.default} />
    {/await}
  {:else if $pageStore === Pages.ERROR}
    {#await import('@pages/error/Error.svelte') then module}
      <svelte:component this={module.default} />
    {/await}
  {:else if $pageStore === Pages.LOADER}
    {#await import('@components/progress/ProgressVideo.svelte') then module}
      <svelte:component this={module.default} />
    {/await}
  {:else}
    {#await import('@pages/profile/Profile.svelte') then module}
      <svelte:component this={module.default} />
    {/await}
  {/if}
</MainApp>
