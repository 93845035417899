<script lang="ts">
  import { Themes } from "../../utils/enums";
  import type { TTheme } from "../../utils/types";
  import { isLoggedInStore } from "../../stores";
  import IsLoggedInIndicator from "./IsLoggedInIndicator.svelte";

  export let options: {
    title: string;
    subtitle?: string | undefined;
    onClick: (...args: unknown[]) => unknown;
    isDisabled?: boolean;
    isMinimized: boolean;
    theme: TTheme;
    isMain?: boolean;
  };

  let { title, subtitle, onClick, isDisabled, theme, isMain } =
    options;

  $: isMinimized = options.isMinimized;
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div
  class="crtw-w-full crtw-flex crtw-flex-row crtw-items-center crtw-justify-start crtw-h-11 crtw-relative crtw-box-border crtw-group"
  class:crtw-justify-center={isMinimized}
  class:crtw-w-11={isMinimized}
  on:click={onClick}
  role="button"
  tabindex={0}
>
  {#if isMinimized && $isLoggedInStore}
    <span
      class="crtw-flex crtw-items-center crtw-justify-center crtw-invisible crtw-bg-white crtw-text-black crtw-text-center crtw-absolute crtw-z-10
      crtw-top-0 crtw-bottom-0 crtw-left-0 crtw-right-0 crtw-text-[9px] crtw-rounded crtw-overflow-hidden group-hover:crtw-visible"
      class:crtw-bg-black={theme === Themes.BLACK}
      class:crtw-text-white={theme === Themes.BLACK}
    >
      {#if isMain}
        <slot />
      {:else}
        {title}
      {/if}
    </span>
  {/if}
  <div
    class="crtw-flex crtw-items-center crtw-relative crtw-h-full crtw-mx-1.5"
  >
    {#if isMain}
      <IsLoggedInIndicator brandName={title} />
    {/if}
    <slot name="icon" />
  </div>
  {#if !isMinimized}
    <div
      class="crtw-flex crtw-flex-col crtw-flex-1 crtw-w-full crtw-ml-1.5 crtw-justify-center"
      class:crtw-text-white={isDisabled}
    >
      {#if subtitle && !$isLoggedInStore}
        <div class="crtw-text-[8px]">{@html subtitle}</div>
      {/if}
      {@html title}
    </div>
    <slot />
  {/if}
</div>
